<template>
    <div
        class="base-notify-alert"
        :style="getAlertStyle"
        :color="`${alert.type}`"
    >
        <i
            :color="getIconClass"
            class="mx-3 text-xl"
            :class="getIcon"
        />

        <span class="px-5">{{ alert.message }}</span>

        <BaseButton
            icon
            :color="getIconClass"
            @click="removeAlert(alert)"
        >
            <i class="fas fa-times" />
        </BaseButton>
    </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
    name: 'BaseNotifyAlert',
    props: {
        alert: {
            type: Object,
            required: true
        }
    },
    computed: {
        getIcon () {
            switch (this.alert.type) {
                case 'error':
                    return 'fas fa-exclamation-circle'
                case 'success':
                    return 'fas fa-check-circle'
                default:
                    return 'fas fa-check-circle'
            }
        },
        getIconClass () {
            switch (this.alert.type) {
                case 'error':
                    return 'white'
                case 'success':
                    return 'white'
                default:
                    return 'success'
            }
        },
        // Get Alert Style
        getAlertStyle () {
            switch (this.alert.type) {
                case 'error':
                    return {
                        color: 'white',
                        backgroundColor: this.tailwindColors.danger.DEFAULT
                    }
                case 'success':
                    return {
                        color: 'white',
                        backgroundColor: this.tailwindColors.success
                    }
                default:
                    return {
                        color: 'white',
                        backgroundColor: 'black'
                    }
            }
        }
    },
    methods: {
        ...mapActions('BaseAlertModule', ['removeAlert'])
    }
}
</script>

<style scoped lang="sass">
.base-notify-alert
    animation: appear 0.6s ease-in-out
    min-height: 50px
    min-width: 300px
    @apply body-1 flex items-center justify-between mt-4 rounded-lg text-black

@keyframes appear
    0%
        opacity: 0
        transform: translateY(50px)
    60%
        opacity: 1
        transform: translateY(30px)
    80%
        transform: translateY(-10px)
    100%
        transform: translateY(0)
</style>
