import { api } from './apiWrapper'

const baseURL = '/forms'

const getAllForms = async (params) => {
    return await api.get(baseURL, params)
}

const getFormsTypes = async () => {
    return await api.get(`${baseURL}/types`)
}

const getForm = async (id) => {
    return await api.get(`${baseURL}/${id}`)
}

const createForm = async (payload) => {
    return await api.post(baseURL, payload)
}

const updateForm = async (formID, payload) => {
    return await api.patch(`${baseURL}/${formID}`, payload)
}

const getFormSubmissionSignatures = async (id) => {
    return await api.get(`form-submissions/${id}/signatures`)
}

const uploadSignature = async (id, payload) => {
    return await api.post(`form-submissions/${id}/signature`, payload)
}

const createFormSubmission = async (payload) => {
    return await api.post('form-submissions', payload)
}

const updateFormSubmission = async (formID, payload) => {
    return await api.patch(`form-submissions/${formID}`, payload)
}

export default {
    getAllForms,
    getFormsTypes,
    getForm,
    createForm,
    updateForm,
    getFormSubmissionSignatures,
    uploadSignature,
    createFormSubmission,
    updateFormSubmission
}
