import _ from 'lodash'
import API from '@/api'

export default {
    namespaced: true,
    state: {
        currencyTypes: [],
        expenseCategories: []
    },
    getters: {
        getCurrencyTypes: (state) => state.currencyTypes,
        getExpenseCategories: (state) => state.expenseCategories
    },
    mutations: {
        SET_CURRENCY_TYPES (state, currencyTypes) {
            state.currencyTypes = currencyTypes
        },
        SET_EXPENSE_CATEGORIES (state, categories) {
            state.expenseCategories = categories
        }
    },
    actions: {
        async setCurrencyTypes ({ commit, state }) {
            try {
                if (!_.isEmpty(state.currencyTypes)) return
                const response = await API.Expenses.getCurrencyTypes()
                commit('SET_CURRENCY_TYPES', response)
            } catch (error) {
                console.error('Error in setCurrencyTypes:', error)
            }
        },
        async setExpenseCategories ({ commit }) {
            try {
                const categories = await API.Tags.getTags({
                    type: 'Expense'
                })
                commit('SET_EXPENSE_CATEGORIES', categories?.data || [])
            } catch (error) {
                console.error('Error in setExpenseCategories:', error)
            }
        }
    }
}
