export default {
    namespaced: true,
    state: {
        notifications: 0,
        version: '1.0.0'
    },
    getters: {
        getNotifications: (state) => state.notifications,
        getVersion: (state) => state.version
    },
    mutations: {
        SET_NOTIFICATIONS (state, notifications) {
            state.notifications = notifications
        },
        SET_VERSION (state, version) {
            state.version = version
        }
    },
    actions: {
        setNotifications ({ commit }, notifications) {
            commit('SET_NOTIFICATIONS', notifications)
        },
        setVersion ({ commit }, version) {
            commit('SET_VERSION', version)
        }
    }
}
