<template>
    <div
        class="media-library-thumb"
        dusk="thumb"
    >
        <img
            v-if="!!imgProps.src && !imageErrored"
            v-bind="imgProps"
            class="media-library-thumb-img"
            @error="imageErrored = true"
            @click="$emit('open')"
        >

        <span
            v-else
            class="media-library-thumb-extension"
            :class="isHEIC ? 'flex flex-col items-center' : ''"
        >
            <span class="media-library-thumb-extension-truncate">{{ imgProps.extension }}</span>
            <a
                v-if="isHEIC"
                class="media-library-thumb-extension-truncate text-[8px] underline"
                :href="imgProps.src"
                target="_blank"
                rel="noopener noreferrer"
            >{{ $t('global.download') }}</a>
        </span>
    </div>
</template>

<script>

export default {
    name: 'MediaLibraryThumb',
    props: {
        uploadInfo: { required: true, type: Object },
        validationRules: { required: false, type: Object },
        imgProps: { required: true, type: Object }
    },
    emits: ['replaced'],
    data: function () {
        return { imageErrored: false, oldImgSrc: this.imgProps.src }
    },
    computed: {
        isHEIC: function () {
            return this.imgProps.extension === 'HEIC'
        }
    },
    watch: {
        imgProps: {
            deep: true,
            immediate: true,
            handler: function (val) {
                if (this.oldImgSrc !== val.src) {
                    this.imageErrored = false
                }
            }
        }
    }
}
</script>

<style>
.media-library-thumb-extension {
    border-style: dashed;
    border-width: 2px;
    border-color: rgba(113, 128, 150, 0.25);
}
</style>
