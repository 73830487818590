import Axios from 'axios'
import router from '@/router'
import store from '@/store'
import db from '@/offline/indexedDBHelper'
import Vue from 'vue'

const pendingRequests = new Map()
let numOfRequests = 0

const AxiosConfig = Axios.create({
    baseURL: import.meta.env.VITE_APP_BASE_API_URL
})

const addToken = (config) => {
    const token = store.getters['AuthModule/getAuthToken']
    if (token) {
        config.headers.Authorization = `Bearer ${token}`
    }
}

const getRequestKey = (config) => {
    return `${config.method}-${config.url}`
}

const abortPendingRequests = () => {
    pendingRequests.forEach((controller, key) => {
        controller.abort()
        pendingRequests.delete(key)
    })
}

AxiosConfig.interceptors.request.use(
    (config) => {
        const controller = new AbortController()
        config.signal = controller.signal

        const requestKey = getRequestKey(config)
        pendingRequests.set(requestKey, controller)

        addToken(config)
        numOfRequests++
        if (store.state.disableLoader) {
            return config
        }
        store.commit('SET_LOADER_TO_STATE', false)
        return config
    },
    (error) => {
        store.commit('SET_LOADER_TO_STATE', true)
        numOfRequests--
        return Promise.reject(error)
    }
)

AxiosConfig.interceptors.response.use(
    (response) => {
        const requestKey = getRequestKey(response.config)
        pendingRequests.delete(requestKey)

        return handleSuccessResponse(response)
    },
    (error) => {
        if (error.config) {
            const requestKey = getRequestKey(error.config)
            pendingRequests.delete(requestKey)
        }

        if (error.name === 'CanceledError' || error.name === 'AbortError') {
            return Promise.reject(new Error('Request aborted'))
        }

        return handleErrorResponse(error)
    }
)

function handleSuccessResponse (response) {
    numOfRequests--
    if (numOfRequests === 0) {
        store.commit('SET_LOADER_TO_STATE', false)
    }
    return response
}

function handleErrorResponse (error) {
    store.commit('SET_LOADER_TO_STATE', false)
    numOfRequests--
    if (numOfRequests === 0) {
        store.commit('SET_LOADER_TO_STATE', false)
    }

    if (Axios.isCancel(error) || error?.code === 'ECONNABORTED') {
        return Promise.reject(error)
    }

    if (!error.response) {
        return handleNetworkError(error)
    }

    const { status, data } = error.response
    return handleHttpError(status, data, error)
}

function handleNetworkError (error) {
    const message = router.app.$i18n.t('global.network-error')
    showAlert(message, 'error')
    return Promise.reject(error)
}

function handleHttpError (status, data, error) {
    switch (status) {
        case 401:
            return handleUnauthorized(data, error)
        case 403:
            return handleForbidden(error)
        case 404:
            return handleNotFound(error)
        case 422:
            return handleValidationError(data, error)
        case 500:
            return handleServerError(error)
        default:
            return handleDefaultError(data, error)
    }
}

function handleUnauthorized (data, error) {
    if (data?.error?.message === 'Unauthenticated.') {
        abortPendingRequests()
        store.dispatch('AuthModule/logoutUser')
        handleDatabaseReset()
        return Promise.reject(error)
    }
    return handleDefaultError(data, error)
}

function handleForbidden (error) {
    const message = router.app.$i18n.t('global.unauthorized')
    showAlert(message, 'error')
    return Promise.reject(error)
}

function handleNotFound (error) {
    const message = router.app.$i18n.t('global.resource-not-found')
    showAlert(message, 'error')
    return Promise.reject(error)
}

function handleValidationError (data, error) {
    if (data.errors) {
        const errors = Object.fromEntries(
            Object.entries(data.errors).map(([field, messages]) => [field, messages[0]])
        )
        return Promise.reject(new Error(JSON.stringify(errors)))
    }
    if (data.message) {
        showAlert(data.message, 'error')
        return Promise.reject(new Error(data.message))
    }
    return handleDefaultError(data, error)
}

function handleServerError (error) {
    const message = router.app.$i18n.t('global.server-error-message')
    showAlert(message, 'error')
    return Promise.reject(error)
}

function handleDefaultError (data, error) {
    if (data.message) {
        showAlert(data.message, 'error')
        return Promise.reject(error)
    }
    const message = router.app.$i18n.t('global.unexpected-error')
    showAlert(message, 'error')
    return Promise.reject(error)
}

function handleDatabaseReset () {
    db.delete()
        .then(() => {
            console.log('Database successfully deleted')
            return db.open()
        })
        .then(() => {
            console.log('db opened')
        })
        .catch((err) => {
            console.error('Database operation failed', err)
        })
}

function showAlert (message, type) {
    Vue.prototype.$showAlert({ message, type })
}

export { AxiosConfig as default, abortPendingRequests, handleDatabaseReset }
