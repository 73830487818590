export default function (val, currency) {
    const CURRENCIES = {
        USD: '$',
        EUR: '€',
        GBP: '£'
    }

    if (!val) return '0.00'

    if (CURRENCIES[currency] === undefined) {
        currency = 'GBP'
    }

    return CURRENCIES[currency] + val.replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
}
