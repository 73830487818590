import DateFilter from '@/filters/DateFilter'
import ShortDateFilter from '@/filters/ShortDateFilter'
import TimeFilter from '@/filters/TimeFilter'
import CurrencyFilter from '@/filters/CurrencyFilter'
import GetFullDateFilter from '@/filters/GetFullDateFilter'
import GetFullMonthDateFilter from '@/filters/GetFullMonthDateFilter'
import CommaSeperated from '@/filters/CommaSeperated'

export default {
    DateFilter,
    ShortDateFilter,
    TimeFilter,
    CurrencyFilter,
    GetFullDateFilter,
    GetFullMonthDateFilter,
    CommaSeperated
}
