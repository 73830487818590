
<div>
    <BaseModal
        v-if="showPreviewModal && !imagesAllowed.includes(fileType)"
        :card-style="{ height: 'auto', width: '500px' }"
        @close="handleModalClose"
    >
        <div class="flex flex-col overflow-y-scroll">
            <div
                v-if="fileType === 'pdf'"
                class="pdf-container"
                :style="iframeStyles"
            >
                <VuePdfEmbed
                    ref="pdfRef"
                    :source="src"
                    :page="currentPage"
                    @rendered="handleDocumentRender"
                />
                <div v-if="isLoadingPDF">
                    Loading PDF...
                </div>
                <div v-else>
                    Total pages {{ pageCount }}
                </div>
                <div
                    v-if="src"
                    class="flex justify-between pb-3 mt-2"
                >
                    <BaseButton
                        class="text-white bg-blue"
                        :disabled="currentPage === 1"
                        @click="currentPage--"
                    >
                        <i class="mr-2 fas fa-chevron-left" />
                        {{ $t('global.previous-page') }}
                    </BaseButton>
                    <BaseButton
                        class="text-white bg-blue"
                        :disabled="currentPage === pageCount"
                        @click="currentPage++"
                    >
                        {{ $t('global.next-page') }}
                        <i class="ml-2 fas fa-chevron-right" />
                    </BaseButton>
                </div>
            </div>
            <iframe
                v-else
                ref="iframe"
                :src="src"
                :style="iframeStyles"
                @load="setImageSize()"
            />
        </div>
        <div
            v-if="showConfirmationActions"
            class="flex justify-end pb-3 mt-4 gap-2"
        >
            <BaseButton
                class="bg-grey-300"
                @click="handleModalClose"
            >
                {{ $t('global.cancel') }}
            </BaseButton>
            <BaseButton
                :disabled="!canConfirm"
                class="text-white bg-green"
                @click="callback(null, true, handleModalClose)"
            >
                {{ $t('global.confirm') }}
            </BaseButton>
        </div>
    </BaseModal>

    <BaseOverlay :loading.sync="loadingFile" />

    <vue-easy-lightbox
        v-if="showPreviewModal && imagesAllowed.includes(fileType) && src"
        esc-disabled
        :visible="true"
        :imgs="[src]"
        @hide="showPreviewModal = false"
    />
</div>
