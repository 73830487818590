
<div>
    <media-library-renderless
        ref="mediaLibraryRenderless"
        :initial-value="initialValue"
        :validation-errors="validationErrors"
        :route-prefix="routePrefix"
        :validation-rules="validationRules"
        :translations="translations"
        :before-upload="beforeUpload"
        :after-upload="afterUpload"
        :name="name"
        :max-items="maxItems"
        :max-size-for-preview-in-bytes="maxSizeForPreviewInBytes"
        :vapor="vapor"
        :vapor-signed-storage-url="vaporSignedStorageUrl"
        :upload-domain="uploadDomain"
        :with-credentials="withCredentials"
        :headers="headers"
        @changed="$emit('change', $event)"
        @is-ready-to-submit-change="$emit('is-ready-to-submit-change', $event)"
        @has-uploads-in-progress-change="$emit('has-uploads-in-progress-change', $event)"
    >
        <div
            slot-scope="{
                state,
                removeMedia,
                getCustomPropertyInputProps,
                getCustomPropertyInputListeners,
                getCustomPropertyInputErrors,
                getNameInputProps,
                getNameInputListeners,
                getNameInputErrors,
                getDropZoneProps,
                getDropZoneListeners,
                getFileInputProps,
                getFileInputListeners,
                getErrors,
                clearObjectErrors,
                clearInvalidMedia,
            }"
        >
            <icons />

            <div
                :class="`media-library media-library-multiple ${state.media?.length == 0 ? 'media-library-empty' : 'media-library-filled'
                } ${sortable && 'media-library-sortable'}`"
            >
                <list-errors
                    :invalid-media="state.invalidMedia"
                    :top-level-errors="validationErrors[name]"
                    @cleared="clearInvalidMedia()"
                />

                <div
                    v-show="state.media && state.media.length"
                    v-dragula="sortable ? state.media : undefined"
                    class="media-library-items"
                    :bag="sortable ? dragulaBagName : undefined"
                >
                    <div
                        v-for="object in state.media"
                        :key="object.attributes.uuid"
                        class="media-library-item media-library-item-row"
                        :data-media-library-uuid="object.attributes.uuid"
                    >
                        <div
                            v-if="sortable"
                            class="dragula-handle media-library-row-drag"
                        >
                            <icon
                                v-if="state.media.length"
                                icon="drag"
                            />
                        </div>

                        <MediaLibraryThumb
                            :upload-info="object.upload"
                            :validation-rules="validationRules"
                            :img-props="{
                                src: object.attributes.medium || object.attributes.preview_url || object.client_preview,
                                alt: object.attributes.name,
                                extension: object.attributes.name ? object.attributes.name.split('.').pop() : '',
                                size: object.attributes.size,
                            }"
                            @open="openPopup(object)"
                        />

                        <item-errors
                            v-if="getErrors(object).length"
                            :object-errors="getErrors(object)"
                            @back="clearObjectErrors(object)"
                        />

                        <template v-else>
                            <slot
                                name="properties"
                                :object="object"
                            >
                                <div class="media-library-properties media-library-properties-fixed">
                                    <div
                                        v-if="object.attributes.extension"
                                        class="media-library-property"
                                    >
                                        {{ object.attributes.extension.toUpperCase() }}
                                    </div>

                                    <div
                                        v-if="object.attributes.size"
                                        class="media-library-property"
                                    >
                                        {{ (object.attributes.size / 1024).toFixed(2) }}
                                    </div>
                                </div>
                            </slot>

                            <slot
                                name="fields"
                                :object="object"
                                :get-custom-property-input-props="(propertyName) => getCustomPropertyInputProps(object, propertyName)
                                "
                                :get-custom-property-input-listeners="(propertyName) => getCustomPropertyInputListeners(object, propertyName)
                                "
                                :get-custom-property-input-errors="(propertyName) => getCustomPropertyInputErrors(object, propertyName)
                                "
                                :get-name-input-props="() => getNameInputProps(object)"
                                :get-name-input-listeners="() => getNameInputListeners(object)"
                                :get-name-input-errors="() => getNameInputErrors(object)"
                            >
                                <div class="media-library-properties">
                                    <div class="media-library-field">
                                        <label class="media-library-label">
                                            {{ window.mediaLibraryTranslations.name }}
                                        </label>
                                        <input
                                            class="media-library-input"
                                            :value="object.attributes.name"
                                            :disabled="!allowImageUploading"
                                            dusk="media-library-field-name"
                                            v-on="getNameInputListeners(object)"
                                        >

                                        <div
                                            v-if="object.upload"
                                            :class="`media-library-progress-wrap mt-[5px] ${
                                                object.upload.hasFinishedUploading ? '' : 'media-library-progress-wrap-loading'
                                            }`"
                                        >
                                            <progress
                                                max="100"
                                                :value="object.upload.uploadProgress"
                                                class="media-library-progress"
                                            />
                                        </div>

                                        <p
                                            v-for="error in getNameInputErrors(object)"
                                            :key="error"
                                            class="media-library-field-error"
                                        >
                                            {{ error }}
                                        </p>
                                    </div>
                                </div>
                            </slot>
                        </template>

                        <div
                            v-if="allowImageUploading"
                            class="media-library-row-remove"
                            dusk="remove"
                            @click.stop="handleMediaRemoval(removeMedia, object)"
                        >
                            <icon icon="remove" />
                        </div>
                    </div>
                </div>

                <hidden-fields
                    :name="name"
                    :media-state="state.media"
                />

                <div
                    v-show="!maxItems || state.media.length < maxItems"
                    class="media-library-uploader"
                >
                    <uploader
                        v-show="allowImageUploading"
                        v-bind="{ ...getDropZoneProps(), ...getFileInputProps() }"
                        add
                        multiple
                        :file-type-help-text="fileTypeHelpText"
                        v-on="{ ...getDropZoneListeners(), ...getFileInputListeners() }"
                    />
                </div>
            </div>
        </div>
    </media-library-renderless>
    <vue-easy-lightbox
        v-if="showMediaPopup"
        esc-disabled
        :visible="showMediaPopup"
        :imgs="[
            {
                src: getMediaURL,
                alt: selectedMedia.name,
                caption: selectedMedia.name
            }
        ]"
        @hide="showMediaPopup = false, selectedMedia = {}"
    />
</div>
