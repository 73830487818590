<template>
    <div>
        <BaseModal
            v-if="showPreviewModal && !imagesAllowed.includes(fileType)"
            :card-style="{ height: 'auto', width: '500px' }"
            @close="handleModalClose"
        >
            <div class="flex flex-col overflow-y-scroll">
                <div
                    v-if="fileType === 'pdf'"
                    class="pdf-container"
                    :style="iframeStyles"
                >
                    <VuePdfEmbed
                        ref="pdfRef"
                        :source="src"
                        :page="currentPage"
                        @rendered="handleDocumentRender"
                    />
                    <div v-if="isLoadingPDF">
                        Loading PDF...
                    </div>
                    <div v-else>
                        Total pages {{ pageCount }}
                    </div>
                    <div
                        v-if="src"
                        class="flex justify-between pb-3 mt-2"
                    >
                        <BaseButton
                            class="text-white bg-blue"
                            :disabled="currentPage === 1"
                            @click="currentPage--"
                        >
                            <i class="mr-2 fas fa-chevron-left" />
                            {{ $t('global.previous-page') }}
                        </BaseButton>
                        <BaseButton
                            class="text-white bg-blue"
                            :disabled="currentPage === pageCount"
                            @click="currentPage++"
                        >
                            {{ $t('global.next-page') }}
                            <i class="ml-2 fas fa-chevron-right" />
                        </BaseButton>
                    </div>
                </div>
                <iframe
                    v-else
                    ref="iframe"
                    :src="src"
                    :style="iframeStyles"
                    @load="setImageSize()"
                />
            </div>
            <div
                v-if="showConfirmationActions"
                class="flex justify-end pb-3 mt-4 gap-2"
            >
                <BaseButton
                    class="bg-grey-300"
                    @click="handleModalClose"
                >
                    {{ $t('global.cancel') }}
                </BaseButton>
                <BaseButton
                    :disabled="!canConfirm"
                    class="text-white bg-green"
                    @click="callback(null, true, handleModalClose)"
                >
                    {{ $t('global.confirm') }}
                </BaseButton>
            </div>
        </BaseModal>

        <BaseOverlay :loading.sync="loadingFile" />

        <vue-easy-lightbox
            v-if="showPreviewModal && imagesAllowed.includes(fileType) && src"
            esc-disabled
            :visible="true"
            :imgs="[src]"
            @hide="showPreviewModal = false"
        />
    </div>
</template>

<script>
import API from '@/api'
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
import EventBus from '../../utils/EventBus'
import { mapGetters } from 'vuex'

export default {
    name: 'BaseDocumentPreview',
    components: { VuePdfEmbed },
    data () {
        return {
            showPreviewModal: false,
            src: '',
            showConfirmationActions: false,
            callback: () => { },
            currentPage: 1,
            pageCount: 0,
            fileType: '',
            type: '',
            innerWidth: 0,
            innerHeight: 0,
            isLandscape: false,
            imagesAllowed: ['jpg', 'jpeg', 'png'],
            loadingFile: false,
            isLoadingPDF: true
        }
    },
    computed: {
        ...mapGetters('NavigationModule', ['isMobile']),
        ...mapGetters('ResourceModule', ['getIfRamsIsDownloading']),
        canConfirm () {
            return this.currentPage === this.pageCount
        },
        iframeStyles () {
            let height = '100%'
            let width = '100%'
            let maxHeight = '100%'
            let maxWidth = '100%'

            let innerHeight, innerWidth

            if (this.fileType !== 'pdf') {
                if (this.type === 'signatures') {
                    innerWidth = '450px'
                    innerHeight = '300px'
                } else {
                    width = window.innerWidth - 50 + 'px'
                    height = window.innerHeight - 400 + 'px'

                    if (this.isMobile) {
                        maxWidth = window.innerWidth - 20 + 'px'
                        maxHeight = window.innerHeight - 20 + 'px'

                        width = maxWidth
                        height = maxHeight
                    }
                }

                height = this.src ? (innerHeight || height) : '100%'
                width = this.src ? (innerWidth || width) : '100%'
            } else if (!this.isMobile) {
                width = window.innerWidth - 300 + 'px'
            } else if (this.isMobile) {
                width = window.innerWidth - 75 + 'px'
            }

            // height = this.innerHeight && this.isMobile ? this.innerHeight : height
            // width = this.innerWidth && this.isMobile ? this.innerWidth : width

            return {
                height,
                width,
                'max-width': maxWidth,
                'max-height': this.fileType !== 'pdf' ? '300px' : maxHeight
            }
        },
        isSupportedFileType () {
            return ['pdf', ...this.imagesAllowed].includes(this.fileType)
        }
    },
    destroyed () {
        EventBus.$off('showDocumentPreview')
    },
    mounted () {
        EventBus.$on('showDocumentPreview', (payload) => {
            if (payload.getConfirmation) {
                this.showConfirmationActions = true
            } else {
                this.showConfirmationActions = false
            }

            if (payload.callback) {
                this.callback = payload.callback
            }

            const isRAMSFile = payload.getConfirmation && payload?.type === 'jobs'
            const previewError = this.showPreview(payload, isRAMSFile)
            if (payload.error && previewError) {
                payload.error()
            }
        })

        this.isLandscape = window.orientation === 90 || window.orientation === -90
        window.addEventListener('orientationchange', this.onOrientationChange)
    },
    beforeDestroy () {
        window.removeEventListener('orientationchange', this.onOrientationChange)
    },
    methods: {
        onOrientationChange () {
            this.isLandscape = window.orientation === 90 || window.orientation === -90
        },
        handleModalClose () {
            this.showPreviewModal = false
        },
        async showPreview ({ filename = '', type = '', content = '' }, isRAMSFile = false) {
            if (type === 'signatures') {
                this.type = 'signatures'
            } else {
                this.type = ''
            }
            this.setFileType(filename)
            this.src = ''
            try {
                if (this.isSupportedFileType) {
                    this.showPreviewModal = true
                }

                if (content) {
                    this.src = content
                    return false
                }

                if (this.imagesAllowed.includes(this.fileType) || this.fileType === 'pdf') {
                    this.$store.commit('SET_DISABLE_LOADER_TO_STATE', true)
                    this.loadingFile = true
                }

                if (this.getIfRamsIsDownloading) {
                    this.loadingFile = true
                    return
                }
                this.src = await API.Models[this.isSupportedFileType ? 'previewModelAttachment' : 'getModelAttachment']({
                    filename,
                    type
                })
                return false
            } catch (e) {
                this.showPreviewModal = false
                if (isRAMSFile) {
                    console.log('error downloading RAMs file')
                    this.$showAlert({
                        message: this.$t('global.error-downloading-rams-file'),
                        type: 'error'
                    })
                    return true
                }
                this.$showConfirmation({
                    title: this.$t('global.server-error'),
                    description: this.$t('global.try-again-later')
                })
                return false
            } finally {
                this.loadingFile = false
                this.$store.commit('SET_DISABLE_LOADER_TO_STATE', false)
            }
        },
        setFileType (filename) {
            if (!filename) return
            const urlWithoutQueryParams = filename.split('?')[0]
            const extension = urlWithoutQueryParams.split('.').pop()

            if (extension.length) {
                this.fileType = extension
            }
        },
        setImageSize () {
            const { iframe } = this.$refs
            const image = iframe.contentWindow.document
                .querySelector('html')
                .querySelector('img')
            if (image) {
                image.style = 'object-fit: contain'
                image.style.height = '100%'
                image.style.width = '100%'

                // image.style.width = 'auto'
                this.innerWidth = `${image.clientWidth}px`
                this.innerHeight = `${image.clientHeight}px`

                // image.style.width = `${image.clientWidth}px`
                image.style.height = `min(100%, ${image.clientHeight}px)`

                // if (this.isLandscape) {
                //     image.style.height = 'auto'
                //     // this.innerHeight = `${image.clientWidth}px`
                //     this.innerHeight = `${image.clientHeight}px`
                //     return
                // }
                // if (this.isMobile) {
                //     image.style.height = 'auto'
                //     this.innerHeight = `${image.clientHeight}px`
                // }
            }
        },
        handleDocumentRender () {
            this.isLoadingPDF = false
            this.pageCount = this.$refs.pdfRef.pageCount
        }
    }
}
</script>
