import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

import Routes from './routes'

Vue.use(VueRouter)

const routes = [
    ...Routes,
    {
        path: '*',
        redirect: { name: 'LoginView' }
    }
]

const router = new VueRouter({
    mode: 'history',
    routes
})

router.afterEach((to) => {
    Vue.nextTick(() => {
        document.title = to.meta.title || 'My Abseil'
    })
})

router.beforeEach((to, from, next) => {
    const isAuthenticated = store.getters['AuthModule/isUserLoggedIn']
    const getUserRole = store.getters['AuthModule/getUserRole']

    if (to.name !== 'LoginView' && !isAuthenticated && to.meta.requiresAuth) {
        next({ name: 'LoginView' })
    } else if (to.name === 'LoginView' && isAuthenticated) {
        goToDefaultView(getUserRole, next)
    } else {
        handleRoles(getUserRole, to, next)
    }
})

const handleRoles = (role, route, next) => {
    const isWrongRole =
        (route.meta.requiresAdmin && role !== 1) ||
        (route.meta.requiresTeamLead && role !== 3) ||
        (route.meta.requiresDriver && role !== 5)
    if (isWrongRole) {
        goToDefaultView(role, next)
    } else {
        next()
    }
}

const goToDefaultView = (role, next) => {
    if (role) {
        switch (role) {
            case 1:
                next({ name: 'DashboardView' })
                break
            case 3:
                next({ name: 'TeamLeadForms' })
                break
            case 5:
                next({ name: 'DriversDashboardView' })
                break
            default:
                next({ name: 'ErrorView' })
        }
    } else {
        next({ name: 'ErrorView' })
    }
}
export default router
