import { FORM_TYPES } from '@/constants.js'

export default {
    methods: {
        constructQuestionObject (questions, override = false) {
            const filtered = [
                ...questions.map((h) => {
                    if (h.input_type_id === FORM_TYPES.HEADER) {
                        return {
                            name: h.name,
                            input_type_id: h.input_type_id,
                            id: h.id,
                            title: h.question_text,
                            question_text: h.question_text,
                            collapsed: false,
                            children: [
                                ...questions.filter(
                                    (q) => q.parent_form_question_id === h.id
                                ).sort((a, b) => a.question_order - b.question_order).map((q) => {
                                    if (q.answer === null) {
                                        return {
                                            ...q,
                                            answer: '',
                                            media: q.media || {}
                                        }
                                    }

                                    if (!q.media || !q.media?.length) {
                                        return {
                                            ...q,
                                            media: q.media || {}
                                        }
                                    }

                                    return q
                                })
                            ]
                        }
                    }
                    return null
                }),
                ...questions.filter(
                    (q) =>
                        q.parent_form_question_id === 0 && q.input_type_id !== FORM_TYPES.HEADER
                ).map((q) => {
                    if (q.answer === null) {
                        return {
                            ...q,
                            answer: '',
                            media: q.media || {}
                        }
                    }
                    if (!q.media || !q.media?.length) {
                        return {
                            ...q,
                            media: q.media || {}
                        }
                    }

                    return q
                })
            ].filter((q) => q)

            if (!override) {
                return structuredClone(filtered)
            }

            this.questions = filtered

            return filtered
        },
        transformToOriginalStructure (questions) {
            const transformed = []

            questions.forEach((q) => {
                if (q.input_type_id === FORM_TYPES.HEADER) {
                    transformed.push({
                        name: q.name,
                        input_type_id: q.input_type_id,
                        question_text: q.question_text,
                        id: q.id,
                        question_order: q.question_order
                    })
                    q.children.forEach((c) => {
                        transformed.push(c)
                    })
                } else {
                    transformed.push(q)
                }
            })

            return transformed
        },
        markMandatoryQuestionsAsTouched () {
            this.questions = this.questions.map((question) => {
                if (question.children) {
                    question.children = question.children.map((child) => {
                        if (child.mandatory && !child.answer) {
                            child.dirty = true
                            child.index = child.id + 1 // Update index to rerender children component
                        }
                        return child
                    })
                } else {
                    if (question.mandatory && !question.answer) {
                        question.dirty = true
                        question.index = (question.index || question.id) + 1 // Update index to rerender children component
                    }
                }
                return question
            })
        },
        handleAnswer ({ question, input }) {
            const targetQuestion = this.questions.find(
                (q) => q.id === question.id
            )
            targetQuestion.answer = input
        },
        checkboxValue (option, question = null) {
            // Initialize a variable to hold the parsed value.
            const questionToUse = question || this.question

            // Check if 'this.question.answer.value' exists.
            if (questionToUse.answer?.value) {
                return option.value
            }

            // If none of the above, return false.
            return false
        }
    }
}
