import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Components from '@/components/globals'
import Filters from '@/filters'
import VueDismiss from '@/mixins/VueDismiss'
import TailwindMixin from '@/mixins/TailwindMixin'
import i18n from './locales'
import { validationMixin } from 'vuelidate/src'
import BaseNotify from '@/plugins/BaseNotifications/BaseNotify'
import BaseConfirmation from '@/plugins/BaseConfirmation/BaseConfirmation'
import BaseDocumentPreview from '@/plugins/BaseDocumentPreview/BaseDocumentPreview'
import VueSignaturePad from 'vue-signature-pad'
import VueEasyLightbox from 'vue-easy-lightbox'
import VModal from 'vue-js-modal/dist/index.nocss.js'
import FloatingVue from 'floating-vue'
import * as Sentry from '@sentry/vue'

import 'vue-js-modal/dist/styles.css'
import '@/assets/css/tailwind.css'
import '@spatie/media-library-pro-styles/dist/styles.css'
import 'floating-vue/dist/style.css'

Vue.config.productionTip = false

Object.keys(Components).forEach((key) => {
    Vue.component(key, Components[key])
})

Object.keys(Filters).forEach((key) => {
    Vue.filter(key, Filters[key])
})

Vue.mixin(TailwindMixin)
Vue.mixin(VueDismiss)
Vue.mixin(validationMixin)

Vue.use(BaseNotify, { store })
Vue.use(BaseConfirmation)
Vue.use(BaseDocumentPreview)
Vue.use(VueSignaturePad)
Vue.use(VueEasyLightbox)
Vue.use(VModal)
Vue.use(FloatingVue)

if (import.meta.env.NODE_ENV === 'production') {
    Sentry.init({
        Vue,
        dsn: import.meta.env.VITE_APP_SENTRY_DNS_URL,
        integrations: [
            new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
                tracePropagationTargets: [
                    'localhost', // Matches local development environment
                    /^https:\/\/apidev\.myabseil\.com/, // Matches API calls for the development environment
                    /^https:\/\/api\.myabseil\.com/ // Matches API calls for the production environment
                ]
            }),
            new Sentry.Replay({
                maskAllText: false,
                blockAllMedia: false
            })
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    })
}

new Vue({
    router,
    i18n,
    store,
    render: (h) => h(App)
}).$mount('#app')
