
<div
    id="app"
>
    <div>
        <InstallationBanner
            v-if="deferredPrompt"
            @install="handleInstall"
            @close="handleClose"
        />
        <router-view />
    </div>
    <div class="main-notifications">
        <BaseNotifyAlert
            v-for="alert in getAlerts"
            :key="alert.id"
            :alert="alert"
        />
    </div>
    <BaseConfirmation />
    <BaseDocumentPreview />
    <BaseLoadingSpinner />
    <BaseBackToTop v-if="areAllowedPages && showBackToTopButton" />
</div>
