<template>
    <div
        class="main-layout"
        :style="{padding}"
    >
        <div
            v-if="isDetailPage || $slots['back-button'] || $slots['delete-button'] || $slots['options']"
            class="flex justify-between mt-5 md:mt-0"
        >
            <slot name="back-button" />
            <BaseButton
                v-if="isDetailPage & options.length === 0"
                primary
                class="ml-auto edit-button"
                type="submit"
                @click="goToEditPage($route.fullPath)"
            >
                {{ $t('global.edit') }}
            </BaseButton>
            <slot name="delete-button" />

            <div
                v-if="getOptions.length > 1"
                class="flex w-full flex-1 justify-end gap-4"
            >
                <BasePanelActions>
                    <template #title>
                        Options
                    </template>
                    <template #items>
                        <a
                            v-for="option in getOptions"
                            :key="option.id"
                            :href="option.url"
                            class="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
                            :class="{'text-red-500': option.isDelete}"
                            role="menuitem"
                            tabindex="-1"
                            @click="option.action"
                        >
                            {{ option.name }}
                        </a>
                    </template>
                </BasePanelActions>
            </div>
        </div>
        <slot />
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { ROLES } from '@/constants.js'

const allowedDriverRoutes = ['DriversInventoryDetailView', 'DriversInventoryEditView']

export default {
    name: 'MainLayout',
    props: {
        padding: {
            type: String,
            default: '20px 20px'
        },
        allowEditButton: {
            type: Boolean,
            default: false
        },
        options: {
            type: Array,
            default: () => []
        }
    },
    computed: {
        ...mapGetters('AuthModule', ['getUserRole']),
        isDetailPage () {
            const { fullPath, name } = this.$route

            if (this.getUserRole === ROLES.DRIVER && this.allowEditButton) {
                return allowedDriverRoutes.includes(name)
            }

            if (fullPath.includes('/details/') && this.getUserRole === ROLES.ADMIN && !fullPath.includes('preview')) {
                return true
            }

            return false
        },
        isEditPage () {
            const { fullPath, name } = this.$route

            if (this.getUserRole === ROLES.DRIVER && this.allowEditButton) {
                return allowedDriverRoutes.includes(name)
            }

            if (fullPath.includes('/edit/') && this.getUserRole === ROLES.ADMIN) {
                return true
            }

            return false
        },
        getOptions () {
            let options = this.options

            if (this.isDetailPage) {
                options = [
                    {
                        name: this.$t('global.edit'),
                        action: () => this.goToEditPage(this.$route.fullPath),
                        isDelete: false
                    },
                    ...options
                ]
            }

            return options
        }
    },
    methods: {
        goToEditPage (fullPath) {
            fullPath = fullPath.replace('/details/', '/edit/')
            this.$router.push(fullPath)
        }
    }
}
</script>

<style scoped lang="sass">
.main-layout
    min-height: calc(100vh - 72px)
    @media screen and (max-width: 676px)
        padding: 10px
        @apply h-full
</style>
