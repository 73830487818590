// import _ from 'lodash'
import API from '@/api'

export default {
    namespaced: true,
    state: {
        documentTypes: [],
        attachmentTypes: [],
        downloadingRams: false
    },
    getters: {
        getDocumentTypes: (state) => state.documentTypes,
        getAttachmentTypes: (state) => state.attachmentTypes,
        getIfRamsIsDownloading: (state) => state.downloadingRams
    },
    mutations: {
        SET_DOCUMENT_TYPES (state, categories) {
            state.documentTypes = categories
        },
        SET_ATTACHMENT_TYPES (state, categories) {
            state.attachmentTypes = categories
        },
        SET_RAMS_DOWNLOADING (state, val) {
            state.downloadingRams = val
        }
    },
    actions: {
        async setDocumentTypes ({ commit }) {
            try {
                // if (!_.isEmpty(state.documentTypes)) return
                const categories = await API.Tags.getTags({
                    type: 'Document'
                })
                commit('SET_DOCUMENT_TYPES', categories?.data || [])
            } catch (e) {
                console.error('Error in setDocumentTypes:', e)
            }
        },
        async setAttachmentTypes ({ commit }) {
            try {
                // if (!_.isEmpty(state.attachmentTypes)) return
                const categories = await API.Tags.getTags({
                    type: 'Attachment'
                })
                commit('SET_ATTACHMENT_TYPES', categories?.data || [])
            } catch (e) {
                console.error('Error in setAttachmentTypes:', e)
            }
        },
        setDownloadingRams ({ commit }, payload) {
            commit('SET_RAMS_DOWNLOADING', payload)
        }
    }
}
