import db from '@/offline/indexedDBHelper'
import { fetchAndStoreData } from '.'
import store from '@/store'
import { api } from './apiWrapper'

const baseURL = '/tags'

const getTags = async (params) => {
    try {
        const url = `${baseURL}/`

        const userId = store.getters['AuthModule/getUserId']

        if (!window.indexedDB) {
            return await api.get(url, {
                ...params,
                dropdown: 1,
                sort: 'name',
                direction: 'asc'
            })
        }

        const offlineData = userId
            ? await db.tags
                .where({ type: params.type, user_id: userId })
                .toArray()
            : []

        if (offlineData.length > 0) {
            return { data: offlineData }
        }

        const data = await api.get(url, {
            ...params,
            dropdown: 1,
            sort: 'name',
            direction: 'asc'
        })

        if (!data) return []

        if (!userId) return data

        // Save to IndexedDB
        const offlineDataToSave = data?.data?.map((item) => ({
            id: item.id,
            type: params.type,
            user_id: userId,
            ...item
        }))

        await db.tags.bulkPut(offlineDataToSave)

        return data
    } catch (error) {
        console.error('Error in getTags:', error)
        throw error
    }
}

const getTagTypes = async () => {
    const url = `${baseURL}/types`
    const data = await fetchAndStoreData(url, db.tags_types)

    const hasItemWithValueProperty = data.some((item) => item.value)

    if (hasItemWithValueProperty) return data.map((item) => item.value)
    return data
}

const addTag = async (payload) => {
    return await api.post(baseURL, payload)
}

const editTag = async (id, payload) => {
    return await api.put(`${baseURL}/${id}`, payload)
}

const deleteTag = async (id) => {
    return await api.delete(`${baseURL}/${id}`)
}

export default {
    getTags,
    getTagTypes,
    addTag,
    editTag,
    deleteTag
}
