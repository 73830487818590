import AuthRoutes from './AuthRoutes'
import AdminRoutes from './admin'
import TeamLeadRoutes from './team-lead'
import DriverRoutes from './driver'

export default [
    ...AuthRoutes,
    ...AdminRoutes,
    ...TeamLeadRoutes,
    ...DriverRoutes
]
