import { api } from './apiWrapper'

const baseURL = '/dashboard'

const getStats = async () => {
    const response = await api.get(`${baseURL}/stats`)
    return response.data
}

const getNotifications = async ({ type = 'all', page = 1 }) => {
    return await api.get(`${baseURL}/notifications?type=${type}&page=${page}`)
}

const clearNotifications = async () => {
    return await api.get(`${baseURL}/notifications/markread`)
}

const clearNotification = async (modelID) => {
    return await api.post(`${baseURL}/notifications/${modelID}/markread`)
}

export default {
    clearNotifications,
    getNotifications,
    getStats,
    clearNotification
}
