<template>
    <div>
        <div
            v-for="(header, i) in filteredQuestions"
            :key="i"
        >
            <BaseExpandable
                v-if="header.children && header.children.length"
                class="mb-4"
            >
                <template #closed>
                    <p class="title-3">
                        {{ header.title }}
                    </p>
                </template>
                <template #expanded>
                    <Question
                        v-for="(question) in header.children"
                        :key="`expanded_question-index-${question.id}_${question?.answer?.id || ''}`"
                        :index="question.id"
                        :question="question"
                        :read-only="readOnly"
                        :allow-image-uploading="allowImageUploading"
                        @input="readOnly ? null : handleAnswer"
                        @is-ready-to-submit-change="$emit('is-ready-to-submit-change', $event)"
                    />
                </template>
            </BaseExpandable>
            <h1 v-else>
                <Question
                    :key="`header-index-${header.id}_${header?.answer?.id}`"
                    :index="header.id"
                    :question="header"
                    :read-only="readOnly"
                    :allow-image-uploading="allowImageUploading"
                    @input="readOnly ? null : handleAnswer"
                    @is-ready-to-submit-change="$emit('is-ready-to-submit-change', $event)"
                />
            </h1>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Question from './Question.vue'
import QuestionMixin from '@/mixins/QuestionMixin'

export default {
    name: 'QuestionList',
    components: {
        Question
    },
    mixins: [QuestionMixin],
    props: {
        questions: {
            type: Array,
            required: true
        },
        readOnly: {
            type: Boolean,
            default: false
        },
        filterArray: {
            type: Boolean,
            default: false
        },
        allowImageUploading: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapGetters('AuthModule', ['getUserRole']),
        filteredQuestions () {
            if (this.filterArray) {
                return this.constructQuestionObject(this.questions)
            }
            return this.questions
        }
    },
    methods: {
        handleAnswer (answer) {
            this.$emit('answer', answer)
        }
    }

}
</script>

<style>

</style>
