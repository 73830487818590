export const ROPE_HOUR_TYPE = {
    ON_SHORE_ON_ROPE: 1,
    ON_SHORE_OTHER: 2,
    OFF_SHORE_ON_ROPE: 3,
    OFF_SHORE_OTHER: 4,
    TRAINING: 5
}

export const FORM_TYPES = {
    YES_NO: 1,
    FREE_TEXT: 2,
    HEADER: 3,
    SHORT: 4,
    CHECKLIST: 5,
    TEXT_DESCRIPTION: 6,
    MEDIA: 7
}

export const ROLES = {
    ADMIN: 1,
    OFFICE_ADMIN: 2,
    TEAM_LEAD: 3,
    OPERATIVE: 4,
    DRIVER: 5
}

export const OPERATIVE_STATUSES = {
    ACTIVE: 1,
    ON_LEAVE: 2,
    INACTIVE: 3
}

export const USER_STATUSES = {
    ACTIVE: 1,
    INACTIVE: 0
}

export const YES_NO = {
    YES: 1,
    NO: 0
}

export const PURPLE_100_COLOR = '#F8F9FE'

export const DELETED = -1
