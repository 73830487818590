import Dexie from 'dexie'

// Create and configure the database
const db = new Dexie('MyAbseilDB')
db.version(5).stores({
    currencies: '++id, user_id',
    expenses_categories: '++id, user_id',
    operatives_levels: '++id, user_id',
    operatives_statuses: '++id, user_id',
    inventory_categories: '++id, user_id',
    inventory_types: '++id, user_id',
    workschedule_statuses: '++id, user_id',
    inspection_statuses: '++id, user_id',
    job_statuses: '++id, user_id',
    inventory_statuses: '++id, user_id',
    roles: '++id, user_id',
    document_types: '++id, user_id',
    files: '++id, user_id, filename',
    form_questions_input_types: '++id, user_id',
    inspections_result_types: '++id, user_id',
    invoice_status_types: '++id, user_id',
    job_status_types: '++id, user_id',
    workschedule_status_types: '++id, user_id',
    tags_types: '++value, user_id',
    tags: 'id, [type+id], user_id',
    versions: '++version, user_id'
})

export default db
