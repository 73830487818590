import moment from 'moment-timezone'
import store from '@/store'

export default function (val, formatWithTime = true, parseWithTimezone = true) {
    if (!val) return '-'

    if (formatWithTime) {
        if (parseWithTimezone) {
            return moment
                .tz(val, store.getters['AuthModule/getTimezone'] ?? 'UTC')
                .locale(store.getters['AuthModule/getUserLanguage'] ?? 'en')
                .format('DD MMM, YYYY')
        }

        return moment.utc(val)
            .locale(store.getters['AuthModule/getUserLanguage'] ?? 'en')
            .format('DD MMM, YYYY')
    }

    const dateArray = val.split('-')
    const year = dateArray[0]
    const month = dateArray[1] - 1 // subtract 1 to correct for 0-based indexing
    const day = dateArray[2].split('T')[0]

    if (parseWithTimezone) {
        const date = moment.tz(new Date(year, month, day), store.getters['AuthModule/getTimezone'] ?? 'UTC').startOf('day')
        const formattedDate = date
            .locale(store.getters['AuthModule/getUserLanguage'] ?? 'en').format('DD MMM, YYYY')

        return formattedDate
    }

    const date = moment.utc(val)
    const formattedDate = date.locale(store.getters['AuthModule/getUserLanguage'] ?? 'en').format('DD MMM, YYYY')

    return formattedDate
}
