import BaseDocumentPreview from './BaseDocumentPreview.vue'
import EventBus from '../../utils/EventBus'

export default {
    install (Vue) {
        Vue.component('BaseDocumentPreview', BaseDocumentPreview)
        Vue.prototype.$showDocumentPreview = (payload) => {
            EventBus.$emit('showDocumentPreview', payload)
        }
    }
}
