export default [
    {
        path: 'invoices',
        name: 'InvoicesView',
        component: () => import('@/views/admin/invoices/InvoicesView.vue'),
        meta: {
            title: 'My Abseil - Invoices',
            requiresAuth: true,
            requiresAdmin: true
        }
    },
    {
        path: 'invoices/edit/:id',
        name: 'InvoicesEditView',
        component: () => import('@/views/admin/invoices/InvoicesEditView.vue'),
        meta: {
            title: 'My Abseil - Invoices',
            requiresAuth: true,
            requiresAdmin: true
        }
    },
    {
        path: 'invoices/details/:id',
        name: 'InvoicesDetailsView',
        component: () => import('@/views/admin/invoices/InvoicesDetailsView.vue'),
        meta: {
            title: 'My Abseil - Invoices',
            requiresAuth: true,
            requiresAdmin: true
        }
    }
]
