
<div
    class="media-library-thumb"
    dusk="thumb"
>
    <img
        v-if="!!imgProps.src && !imageErrored"
        v-bind="imgProps"
        class="media-library-thumb-img"
        @error="imageErrored = true"
        @click="$emit('open')"
    >

    <span
        v-else
        class="media-library-thumb-extension"
        :class="isHEIC ? 'flex flex-col items-center' : ''"
    >
        <span class="media-library-thumb-extension-truncate">{{ imgProps.extension }}</span>
        <a
            v-if="isHEIC"
            class="media-library-thumb-extension-truncate text-[8px] underline"
            :href="imgProps.src"
            target="_blank"
            rel="noopener noreferrer"
        >{{ $t('global.download') }}</a>
    </span>
</div>
