export default {
    namespaced: true,
    state: {
        showMenu: false,
        windowWidth: 0,
        isExpanded: false,
        showBackToTopButton: false
    },
    getters: {
        getShowMenu: (state) => state.showMenu,
        getWindowWidth: (state) => state.windowWidth,
        isMobile: (state) => state.windowWidth <= 767,
        isExpanded: (state) => state.isExpanded,
        showBackToTopButton: (state) => state.showBackToTopButton
    },
    mutations: {
        SET_SHOW_MENU (state, status) {
            state.showMenu = status
        },
        SET_WINDOW_WIDTH (state, width) {
            state.windowWidth = width
        },
        SET_NAVIGATION_EXPANSION (state, status) {
            state.isExpanded = status
        },
        SET_BACK_TO_TOP_BUTTON (state, status) {
            state.showBackToTopButton = status
        }
    }
}
