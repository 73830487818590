import API from '@/api'

export default {
    namespaced: true,
    state: {
        tenant: {}
    },
    getters: {
        getTenant: (state) => state.tenant
    },
    mutations: {
        SET_TENANT (state, tenant) {
            state.tenant = tenant
        }
    },
    actions: {
        async getTenantById ({ commit }, id) {
            try {
                const tenant = await API.Tenant.getTenant(id)
                commit('SET_TENANT', tenant)
            } catch (e) {
                console.error('Error in getTenantById:', e)
            }
        },
        async updateTenant ({ commit }, { id, payload }) {
            try {
                const tenant = await API.Tenant.updateTenant(id, payload)
                commit('SET_TENANT', tenant)
            } catch (e) {
                console.error('Error in updateTenant:', e)
            }
        }
    }
}
