import { fileNameParser } from '@/utils/Utilities'
import { api } from './apiWrapper'

const getAll = async (modelName, params) => {
    return await api.get(`/${modelName}`, params)
}

const getModelByID = async (modelName, modelID) => {
    const response = await api.get(`/${modelName}/${modelID}`)
    return response.data
}

const createModel = async (modelName, modelPayload) => {
    const response = await api.post(`/${modelName}`, modelPayload)
    return response.data
}

const updateModel = async (modelName, modelID, modelPayload) => {
    const response = await api.patch(`/${modelName}/${modelID}`, modelPayload)
    return response.data
}

const deleteModel = async (modelName, modelID, endpoint = '') => {
    return await api.delete(`/${modelName}/${modelID}/${endpoint}`)
}

const restoreModel = async (modelName, modelID) => {
    return await api.post(`/${modelName}/${modelID}/restore`)
}

const getModelAttachment = async (params) => {
    const url = '/download-file'
    const filename =
            params.original_filename ||
            fileNameParser(params.filename, params.type)
    const requiredParams = { filename: params.filename, type: params.type }

    const data = await api.get(url, requiredParams, { responseType: 'blob' })

    const fileURL = window.URL.createObjectURL(new Blob([data]))
    const fileLink = document.createElement('a')

    fileLink.href = fileURL
    fileLink.setAttribute('download', filename)
    document.body.appendChild(fileLink)

    fileLink.click()

    if (!data) return []

    return data
}

const previewModelAttachment = async (params) => {
    const url = '/download-file'

    const data = await api.get(url, params, { responseType: 'blob' })

    const objectUrl = URL.createObjectURL(data)

    return objectUrl
}

const deleteFileAttachment = async (id) => {
    return await api.delete(`file-attachment/${id}`)
}

const deleteFileResource = async (id) => {
    return await api.delete(`documents/${id}`)
}

const exportToPDF = async (
    modelName,
    params = {},
    method = 'get',
    filename = '',
    endpoint = 'generate_pdf'
) => {
    const url = `${modelName}/${endpoint}`

    const lowercaseMethod = method.toLowerCase()

    const blobHeader = {
        responseType: 'blob'
    }

    const data = lowercaseMethod === 'get' ? await api.get(url, params, blobHeader) : await api.post(url, { ...params })

    const fileURL = window.URL.createObjectURL(new Blob([data]))
    const fileLink = document.createElement('a')

    fileLink.href = fileURL
    fileLink.setAttribute('download', `${filename}.pdf`)
    document.body.appendChild(fileLink)

    fileLink.click()

    if (!data) return []

    return data
}

const deleteSignature = async (modelName, modelID) => {
    return await api.delete(`/${modelName}/${modelID}`)
}

const deleteMedia = async (modelName, questionId, mediaId) => {
    return await api.post(`/${modelName}/${questionId}/delete_media/${mediaId}`)
}

export default {
    createModel,
    deleteModel,
    getAll,
    getModelByID,
    updateModel,
    getModelAttachment,
    previewModelAttachment,
    deleteFileAttachment,
    exportToPDF,
    deleteFileResource,
    deleteSignature,
    deleteMedia,
    restoreModel
}
