export const fileNameParser = (string, type) => {
    let lengthToRemove = 0

    switch (type) {
        case 'clients':
            lengthToRemove = 43
            break
        case 'operatives':
            lengthToRemove = 42
            break
        case 'jobs':
            lengthToRemove = 37
            break
        case 'inventoryitems':
            lengthToRemove = 54
            break
        case 'rams':
            lengthToRemove = 50
            break
        default:
            lengthToRemove = 30
            break
    }

    return string.split('').splice(lengthToRemove).join('')
}

export const slugify = (string) => {
    if (!string) return ''
    return string.replace(/\s+/g, '-').toLowerCase()
}
