import db from '@/offline/indexedDBHelper'
import { fetchAndStoreData } from '.'

const baseURL = '/inspections'

const getResultTypes = async () => {
    const url = `${baseURL}/result-types`
    return await fetchAndStoreData(url, db.inspections_result_types)
}

export default {
    getResultTypes
}
