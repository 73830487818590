import _ from 'lodash'
import API from '@/api'

export default {
    namespaced: true,
    state: {
        operatives: [],
        operativesLevels: [],
        operativesStatuses: [],
        operativesTypes: []
    },
    getters: {
        getOperatives: (state) => state.operatives,
        getOperativesLevels: (state) => state.operativesLevels,
        getOperativesStatuses: (state) => state.operativesStatuses,
        getOperativesTypes: (state) => state.operativesTypes
    },
    mutations: {
        SET_OPERATIVES (state, operatives) {
            state.operatives = operatives
        },
        SET_OPERATIVES_LEVELS (state, levels) {
            state.operativesLevels = levels
        },
        SET_OPERATIVES_STATUSES (state, statuses) {
            state.operativesStatuses = statuses
        },
        SET_OPERATIVES_TYPES (state, types) {
            state.operativesTypes = types
        }
    },
    actions: {
        async setOperatives ({ commit }, params = {}) {
            try {
                if (Object.prototype.hasOwnProperty.call(params, 'autocomplete')) {
                    if (params.autocomplete.length < 2) {
                        commit('SET_OPERATIVES', [])
                        return
                    }
                }

                const operatives = await API.Models.getAll('operatives', { ...params, sort: 'first_name' })
                commit('SET_OPERATIVES', operatives)
            } catch (e) {
                console.error('Error in setOperatives:', e)
            }
        },
        async setOperativesLevels ({ commit, state }) {
            try {
                if (!_.isEmpty(state.operativesLevels)) return
                const operativesLevels = await API.Operatives.getLevels()
                commit('SET_OPERATIVES_LEVELS', operativesLevels)
            } catch (e) {
                console.error('Error in setOperativesLevels:', e)
            }
        },
        async setOperativesStatuses ({ commit, state }) {
            try {
                if (!_.isEmpty(state.operativesStatuses)) return
                const operativesStatuses = await API.Operatives.getStatuses()
                commit('SET_OPERATIVES_STATUSES', operativesStatuses)
            } catch (e) {
                console.error('Error in setOperativesStatuses:', e)
            }
        },
        async setOperativesTypes ({ commit }) {
            try {
                const categories = await API.Tags.getTags({
                    type: 'Operative'
                })
                commit('SET_OPERATIVES_TYPES', categories?.data || [])
            } catch (e) {
                console.error('Error in setOperativesTypes:', e)
            }
        }
    }
}
