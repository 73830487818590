import BaseConfirmation from './BaseConfirmation.vue'
import EventBus from '../../utils/EventBus'

export default {
    install (Vue) {
        Vue.component('BaseConfirmation', BaseConfirmation)
        Vue.prototype.$showConfirmation = (payload) => {
            EventBus.$emit('showConfirmation', payload)
        }
    }
}
