import API from '@/api'

export default {
    namespaced: true,
    state: {
        clients: [],
        clientCategories: []
    },
    getters: {
        getClients: (state) => state.clients,
        getClientCategories: (state) => state.clientCategories
    },
    mutations: {
        SET_CLIENTS (state, clients) {
            state.clients = clients
        },
        SET_CLIENT_CATEGORIES (state, categories) {
            state.clientCategories = categories
        }
    },
    actions: {
        async setClients ({ commit }, params = {}) {
            try {
                if (Object.prototype.hasOwnProperty.call(params, 'autocomplete')) {
                    const { autocomplete } = params

                    if (autocomplete.length < 2) {
                        commit('SET_CLIENTS', [])
                        return
                    }
                }

                const clients = await API.Models.getAll('clients', { ...params, sort: 'name' })
                commit('SET_CLIENTS', clients.data)
            } catch (e) {
                console.error('Error in setClients:', e)
            }
        },
        async setClientCategories ({ commit }) {
            try {
                const categories = await API.Tags.getTags({
                    type: 'Client'
                })
                commit('SET_CLIENT_CATEGORIES', categories?.data || [])
            } catch (e) {
                console.error('Error in setClientCategories:', e)
            }
        }
    }
}
