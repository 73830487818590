// Imports
import TeamLeadRoutes from './TeamLeadRoutes'

export default [
    {
        path: '/team-lead',
        component: () => import('@/views/TeamLeadRouter.vue'),
        meta: { requiresTeamLead: true },
        redirect: { name: 'TeamLeadOperativesView' },
        children: [...TeamLeadRoutes]
    }
]
