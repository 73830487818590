import { api } from './apiWrapper'

const baseURL = '/instructions'

const getAllInstructions = async (workScheduleID) => {
    const url = `${baseURL}?work_schedule_id=${workScheduleID}`
    return await api.get(url)
}

const getAllInstructionsFilter = async (filters) => {
    return await api.get(baseURL, filters)
}

const createInstruction = async (workScheduleID, createInstructionsPayload) => {
    const { title, body, recipients } = createInstructionsPayload
    const payload = {
        work_schedule_id: workScheduleID,
        title,
        body,
        recipients
    }
    return await api.post(baseURL, payload)
}

const updateInstructionStatus = async (instructionID, status) => {
    const url = `${baseURL}/${instructionID}/change-status?status=${status}`
    return await api.post(url)
}

export default {
    getAllInstructions,
    getAllInstructionsFilter,
    createInstruction,
    updateInstructionStatus
}
