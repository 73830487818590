export default [
    {
        path: 'forms',
        name: 'AdminFormsView',
        component: () => import('@/views/admin/forms/FormsView.vue'),
        meta: {
            title: 'Forms',
            requiresAuth: true,
            requiresAdmin: true
        }
    },
    {
        path: 'forms/details/:id',
        name: 'AdminFormsDetailsView',
        component: () => import('@/views/admin/forms/FormsDetailsView.vue'),
        meta: {
            title: 'Form details',
            requiresAuth: true,
            requiresAdmin: true
        }
    },
    {
        path: 'forms/edit/:id',
        name: 'AdminFormsEditView',
        component: () => import('@/views/admin/forms/FormsEditView.vue'),
        meta: {
            title: 'Form edit',
            requiresAuth: true,
            requiresAdmin: true
        }
    },
    {
        path: 'forms/details/:id/preview',
        name: 'FormPreview',
        component: () => import('@/views/admin/forms/FormPreview.vue'),
        meta: {
            title: 'Form preview',
            requiresAuth: true,
            requiresAdmin: true
        }
    }
]
