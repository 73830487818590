
<div
    :id="question.mandatory && !questionHasAnswer && !readOnly ? 'mandatory' : ''"
    class="my-3"
>
    <div class="mb-3">
        <span
            class="question-title my-auto ml-2 block"
        >
            <span
                :class="classes"
                class="text--description"
                v-html="question.question_text"
            />
            <div
                v-if="question.question_help"
                class="
                bg-grey-400
                rounded-full
                w-5
                inline-flex
                ml-2
                justify-center
                my-auto
                h-5
                has-tooltip
            "
            >
                <i class="fas fa-question fa-sm m-auto cursor-pointer" />
                <span
                    class="
                    tooltip
                    bg-white
                    rounded
                    shadow-lg
                    p-1
                    bg-gray-100
                    text-red-500
                    -mt-10
                    p-3
                    left-0
                    md:left-auto
                "
                >{{ question.question_help }}</span>
            </div>
        </span>
    </div>
    <template v-if="readOnly">
        <div
            v-if="![FORM_TYPES.TEXT_DESCRIPTION, FORM_TYPES.CHECKLIST, FORM_TYPES.MEDIA].includes(question.input_type_id)"
            class="body-3 mt-2 text-grey-800 px-8"
        >
            <b> {{ computedVal || '-' }}</b>
        </div>
        <MediaLibraryCollection
            v-if="[FORM_TYPES.FREE_TEXT, FORM_TYPES.MEDIA, FORM_TYPES.TEXT_DESCRIPTION].includes(question.input_type_id)"
            :ref="`mediaLibrary_${question.id}_${question.answer?.id}`"
            :answer-id="question.answer?.id"
            class="mt-4 cursor-pointer"
            :initial-value="question.answer && question.answer.media?.length ? question.answer.media : []"
            :name="'media'"
            :upload-domain="getUploadDomain"
            :headers="getUploadHeaders"
            :validation-rules="{ accept: ['image/png', 'image/jpeg', 'image/jpg', 'image/heic'], maxSizeInKB: 20480 }"
            disabled
            :allow-image-uploading="allowImageUploading"
            :sortable="false"
            @change="handleMediaAdded($event)"
            @is-ready-to-submit-change="$emit('is-ready-to-submit-change', $event)"
        />
        <div
            v-if="question.input_type_id === FORM_TYPES.CHECKLIST"
            class="w-full ml-3"
        >
            <div
                v-for="(option, qIndex) in getChecklistOptions"
                :key="`ckb-option-${qIndex}`"
                class="mb-3 flex items-center"
            >
                <input
                    id="mandatory-field"
                    type="checkbox"
                    class="ml-2 w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 cursor-pointer"
                    :disabled="readOnly"
                    :checked="checkboxValue(option)"
                >

                <label
                    for="mandatory-field"
                    class="text-sm font-medium text-grey-800 body-3 ml-2"
                >{{ option.label }}</label>
            </div>
        </div>
        <div v-if="FORM_TYPES.TEXT_DESCRIPTION === question.input_type_id">
            <div class="grid grid-cols-1 md:grid-cols-6 gap-4">
                <img
                    v-for="(media, index) in question.media"
                    :key="index"
                    :src="media.small || media.url"
                    class="col-span-1 w-full max-w-[300px] cursor-pointer"
                    @click="showLightbox = true"
                >
                <vue-easy-lightbox
                    v-if="showLightbox"
                    esc-disabled
                    :visible="showLightbox"
                    :imgs="getMediaSrc(question.media)"
                    @hide="showLightbox = false"
                />
            </div>
        </div>
    </template>
    <template v-else>
        <div
            v-if="question.input_type_id === FORM_TYPES.YES_NO"
            class="flex ml-2 col-col"
        >
            <div class="flex gap-2 mr-5">
                <input
                    v-model="computedVal"
                    type="radio"
                    value="Yes"
                    :name="index"
                    class="w-6 h-6 my-auto form-checkbox"
                    :disabled="readOnly"
                >
                <label
                    class="p-0 my-auto"
                    :class="errorClasses"
                >Yes</label>
            </div>
            <div class="flex gap-2 mr-5">
                <input
                    v-model="computedVal"
                    type="radio"
                    value="No"
                    :name="index"
                    :disabled="readOnly"
                    class="w-6 h-6 my-auto form-checkbox"
                >
                <label
                    class="my-auto"
                    :class="errorClasses"
                >{{ $t('global.no') }}</label>
            </div>
            <div class="flex gap-2">
                <input
                    v-model="computedVal"
                    type="radio"
                    value="N/A"
                    :name="index"
                    :disabled="readOnly"
                    class="w-6 h-6 my-auto form-checkbox"
                >
                <label
                    class="my-auto"
                    :class="errorClasses"
                >{{ $t('global.not-applicable') }}</label>
            </div>
        </div>
        <div
            v-if="question.input_type_id === FORM_TYPES.SHORT"
            class="w-full"
        >
            <BaseInput
                v-model="computedVal"
                placeholder="Type something"
                :disabled="readOnly"
            />
        </div>
        <div
            v-if="question.input_type_id === FORM_TYPES.FREE_TEXT"
            class="ml-2"
        >
            <BaseTextarea
                v-model="computedVal"
                :disabled="readOnly"
                @attachment="showMediaLibrary = !showMediaLibrary"
            />
            <template>
                <div
                    v-if="allowImageUploading"
                    class="cursor-pointer mt-2 flex items-center"
                    title="Add Attachment"
                    @click="showMediaLibrary = !showMediaLibrary"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#9196AF"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-image"
                    ><rect
                        x="3"
                        y="3"
                        width="18"
                        height="18"
                        rx="2"
                        ry="2"
                    /><circle
                        cx="8.5"
                        cy="8.5"
                        r="1.5"
                    /><polyline points="21 15 16 10 5 21" /></svg>
                    <span class="ml-2 text-grey-800 select-none">
                        {{ !showMediaLibrary ? $t('global.add-images') : $t('global.hide-images') }}
                    </span>
                </div>
                <MediaLibraryCollection
                    v-if="showMediaLibrary"
                    :ref="`mediaLibrary_${question.id}`"
                    :answer-id="question.answer?.id"
                    class="mt-4 cursor-pointer"
                    :initial-value="question.answer ? question.answer.media : []"
                    :name="'media'"
                    :upload-domain="getUploadDomain"
                    :headers="getUploadHeaders"
                    :validation-rules="{ accept: ['image/png', 'image/jpeg', 'image/jpg', 'image/heic'], maxSizeInKB: 20480 }"
                    disabled
                    :allow-image-uploading="allowImageUploading"
                    :sortable="false"
                    @change="handleMediaAdded($event)"
                    @is-ready-to-submit-change="$emit('is-ready-to-submit-change', $event)"
                />
            </template>
        </div>
        <div
            v-if="question.input_type_id === FORM_TYPES.MEDIA"
            class="ml-2"
        >
            <template>
                <div
                    v-if="allowImageUploading"
                    class="cursor-pointer mt-2 flex items-center"
                    title="Add Attachment"
                    @click="showMediaLibrary = !showMediaLibrary"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#9196AF"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-image"
                    ><rect
                        x="3"
                        y="3"
                        width="18"
                        height="18"
                        rx="2"
                        ry="2"
                    /><circle
                        cx="8.5"
                        cy="8.5"
                        r="1.5"
                    /><polyline points="21 15 16 10 5 21" /></svg>
                    <span class="ml-2 text-grey-800 select-none">
                        {{ !showMediaLibrary ? $t('global.add-images') : $t('global.hide-images') }}
                    </span>
                </div>
                <MediaLibraryCollection
                    v-if="showMediaLibrary"
                    :ref="`mediaLibrary_${question.id}`"
                    :answer-id="question.answer?.id"
                    class="mt-4 cursor-pointer"
                    :initial-value="question.answer ? question.answer.media : []"
                    :name="'media'"
                    :upload-domain="getUploadDomain"
                    :headers="getUploadHeaders"
                    :validation-rules="{ accept: ['image/png', 'image/jpeg', 'image/jpg', 'image/heic'], maxSizeInKB: 20480 }"
                    disabled
                    allow-image-uploading
                    :sortable="false"
                    :max-items="1"
                    @change="handleMediaAdded($event)"
                    @is-ready-to-submit-change="$emit('is-ready-to-submit-change', $event)"
                />
            </template>
        </div>
        <div
            v-if="question.input_type_id === FORM_TYPES.CHECKLIST"
            class="w-full ml-3"
        >
            <div
                v-for="(option, qIndex) in getChecklistOptions"
                :key="`ckb-option-${qIndex}`"
                class="mb-3 flex items-center"
            >
                <input
                    id="mandatory-field"
                    :value="checkboxValue(option)"
                    type="checkbox"
                    class="ml-2 w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 cursor-pointer"
                    :disabled="readOnly"
                    :checked="checkboxValue(option)"
                    @change="updateCheckboxValue($event, option)"
                >

                <label
                    for="mandatory-field"
                    class="text-sm font-medium text-grey-800 body-3 ml-2"
                >{{ option.label }}</label>
            </div>
        </div>
        <div v-if="FORM_TYPES.TEXT_DESCRIPTION === question.input_type_id">
            <div class="grid grid-cols-1 md:grid-cols-6 gap-4">
                <img
                    v-for="(media, index) in question.media"
                    :key="index"
                    :src="media.small || media.url"
                    class="col-span-1 w-full max-w-[300px] cursor-pointer"
                    @click="showLightbox = true"
                >
                <vue-easy-lightbox
                    v-if="showLightbox"
                    esc-disabled
                    :visible="showLightbox"
                    :imgs="getMediaSrc(question.media)"
                    @hide="showLightbox = false"
                />
            </div>
        </div>
    </template>
</div>
