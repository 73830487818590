import { api } from './apiWrapper'

/**
 * Logs in a user
 * @param {Object} loginPayload - The login credentials
 * @returns {Promise<Object>} The login response
 */
const loginUser = async (loginPayload) => {
    return api.post('/login', loginPayload)
}

/**
 * Fetches user data
 * @param {number} id - The user ID
 * @returns {Promise<Object>} The user data
 */
const fetchUser = async (id) => {
    return api.get(`/users/${id}`)
}

export default {
    fetchUser,
    loginUser
}
