import { api } from './apiWrapper'
import db from '@/offline/indexedDBHelper'
import { fetchAndStoreData } from '.'

const baseURL = '/jobs'

const getStatuses = async () => {
    const url = `${baseURL}/status-types`
    return await fetchAndStoreData(url, db.job_status_types)
}

const getByDateRange = async (startDate, endDate) => {
    const url = `${baseURL}/bydate`
    const params = { start_date: startDate, end_date: endDate }
    return await api.get(url, params)
}

const addForm = async (id, payload) => {
    const url = `${baseURL}/${id}/add-form`
    return await api.post(url, payload)
}

const removeForm = async (id, payload) => {
    const url = `${baseURL}/${id}/remove-form`
    return await api.post(url, payload)
}

export default {
    getStatuses,
    getByDateRange,
    addForm,
    removeForm
}
