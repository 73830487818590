import { api } from './apiWrapper'

const baseURL = '/users'

const getTimeZones = async () => {
    const url = `${baseURL}/available-timezones`
    return await api.get(url)
}

const onTrial = async () => {
    const url = `${baseURL}/on-trial`
    return await api.get(url)
}

export default {
    getTimeZones,
    onTrial
}
