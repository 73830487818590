import db from '@/offline/indexedDBHelper'
import { fetchAndStoreData } from '.'

const baseURL = '/invoices'

const getStatuses = async () => {
    const url = `${baseURL}/status-types`
    return await fetchAndStoreData(url, db.invoice_status_types)
}

export default {
    getStatuses
}
