import { api } from './apiWrapper'
import db from '@/offline/indexedDBHelper'
import { fetchAndStoreData } from '.'

const baseURL = '/operatives'

const getStatuses = async () => {
    const url = `${baseURL}/status-types`
    return await fetchAndStoreData(url, db.operatives_statuses)
}

const getLevels = async () => {
    const url = `${baseURL}/level-types`
    return await fetchAndStoreData(url, db.operatives_levels)
}

const checkIn = async (payload) => {
    const url = `${baseURL}/checkin`
    return await api.post(url, payload)
}

const checkOut = async (payload) => {
    const url = `${baseURL}/checkout`
    return await api.post(url, payload)
}

const switchTeamLead = async (payload) => {
    const url = `${baseURL}/checkout`
    return await api.post(url, payload)
}

const addSignature = async (payload) => {
    const url = '/operative-signature'
    return await api.post(url, payload)
}

const getSignatures = async (payload) => {
    const url = '/operative-signature/'
    return await api.get(url, payload)
}

export default {
    checkIn,
    checkOut,
    getLevels,
    getStatuses,
    switchTeamLead,
    addSignature,
    getSignatures
}
