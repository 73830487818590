
<div
    class="main-layout"
    :style="{padding}"
>
    <div
        v-if="isDetailPage || $slots['back-button'] || $slots['delete-button'] || $slots['options']"
        class="flex justify-between mt-5 md:mt-0"
    >
        <slot name="back-button" />
        <BaseButton
            v-if="isDetailPage & options.length === 0"
            primary
            class="ml-auto edit-button"
            type="submit"
            @click="goToEditPage($route.fullPath)"
        >
            {{ $t('global.edit') }}
        </BaseButton>
        <slot name="delete-button" />

        <div
            v-if="getOptions.length > 1"
            class="flex w-full flex-1 justify-end gap-4"
        >
            <BasePanelActions>
                <template #title>
                    Options
                </template>
                <template #items>
                    <a
                        v-for="option in getOptions"
                        :key="option.id"
                        :href="option.url"
                        class="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
                        :class="{'text-red-500': option.isDelete}"
                        role="menuitem"
                        tabindex="-1"
                        @click="option.action"
                    >
                        {{ option.name }}
                    </a>
                </template>
            </BasePanelActions>
        </div>
    </div>
    <slot />
</div>
