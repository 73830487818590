// Clients Routes
export default [
    {
        path: 'clients',
        name: 'ClientsView',
        component: () => import('@/views/admin/clients/ClientsView.vue'),
        meta: {
            title: 'My Abseil - Clients',
            requiresAuth: true,
            requiresAdmin: true
        }
    },
    {
        path: 'clients/edit/:id',
        name: 'ClientsEditView',
        component: () => import('@/views/admin/clients/ClientsEditView.vue'),
        meta: {
            title: 'My Abseil - Clients',
            requiresAuth: true,
            requiresAdmin: true
        }
    },
    {
        path: 'clients/details/:id',
        name: 'ClientsDetailsView',
        component: () => import('@/views/admin/clients/ClientsDetailsView.vue'),
        meta: {
            title: 'My Abseil - Clients',
            requiresAuth: true,
            requiresAdmin: true
        }
    }
]
