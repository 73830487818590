import { fetchAndStoreData } from '.'
import db from '@/offline/indexedDBHelper'

const baseURL = '/inventoryitems'

const getMainTypes = async () => {
    const url = `${baseURL}/types`

    const data = await fetchAndStoreData(url, db.inventory_types)

    return data.map((t) => {
        return {
            id: t.id,
            value: t.name,
            name: t.name
        }
    })
}

const getStatusTypes = async () => {
    const url = `${baseURL}/status-types`

    return await fetchAndStoreData(url, db.inventory_statuses)
}

const getTags = async () => {
    const url = `${baseURL}/tags`

    return await fetchAndStoreData(url, db.inventory_categories)
}

export default {
    getMainTypes,
    getStatusTypes,
    getTags
}
