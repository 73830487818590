import BaseNotifyAlert from './BaseNotifyAlert.vue'

export default {
    install (Vue, { store }) {
        store.registerModule('BaseAlertModule', BaseAlertModule)

        Vue.component('BaseNotifyAlert', BaseNotifyAlert)

        Vue.prototype.$showAlert = (alert) => {
            store.dispatch('BaseAlertModule/setAlert', alert)
        }
    }
}

const BaseAlertModule = {
    namespaced: true,
    state: {
        alerts: []
    },
    getters: {
        getAlerts: (state) => state.alerts
    },
    mutations: {
        SET_ALERT (state, alert) {
            state.alerts.push(alert)
        },
        REMOVE_ALERT (state, alert) {
            const foundAlert = state.alerts.find((a) => a.id === alert.id)
            const foundAlertIndex = state.alerts.indexOf(foundAlert)

            state.alerts.splice(foundAlertIndex, 1)
        }
    },
    actions: {
        setAlert ({ commit }, alert) {
            commit('SET_ALERT', alert)
            setTimeout(() => {
                commit('REMOVE_ALERT', alert)
            }, alert.timeout || 5000)
        },
        removeAlert ({ commit }, alert) {
            commit('REMOVE_ALERT', alert)
        }
    }
}
