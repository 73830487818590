export default [
    {
        path: 'payments',
        name: 'PaymentsView',
        component: () => import('@/views/admin/payments/PaymentsView.vue'),
        meta: {
            title: 'My Abseil - Payments',
            requiresAuth: true,
            requiresAdmin: true
        }
    },
    {
        path: 'payments/edit/:id',
        name: 'PaymentsEditView',
        component: () => import('@/views/admin/payments/PaymentsEditView.vue'),
        meta: {
            title: 'My Abseil - Payments',
            requiresAuth: true,
            requiresAdmin: true
        }
    },
    {
        path: 'payments/details/:id',
        name: 'PaymentsDetailsView',
        component: () => import('@/views/admin/payments/PaymentsDetailsView.vue'),
        meta: {
            title: 'My Abseil - Payments',
            requiresAuth: true,
            requiresAdmin: true
        }
    }
]
