import Vue from 'vue'
import VueI18n from 'vue-i18n'
import store from '../store'
import fr from './fr.json'
import en from './en.json'

Vue.use(VueI18n)

// eslint-disable-next-line
const i18n = new VueI18n({
    locale: store.getters['AuthModule/getUserLanguage'],
    messages: {
        en,
        fr
    }
})

export default i18n
