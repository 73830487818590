import moment from 'moment-timezone'
import store from '../store'

export default function (val, format = 'dddd, DD MMM YYYY', parseWithTimezone = true) {
    if (!val) return '-'
    const language = store.getters['AuthModule/getLanguage']

    if (parseWithTimezone) {
        const timezone = store.getters['AuthModule/getTimezone']
        return moment.tz(val, timezone).locale(language ?? 'en').format(format)
    }

    return moment(val).locale(language ?? 'en').format(format)
}
