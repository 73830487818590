import i18n from '@/locales'

export default [
    {
        path: 'dashboard',
        name: 'DriversDashboardView',
        component: () => import('@/views/driver/DriversDashboardView.vue'),
        meta: { requiresAuth: true, requiresDriver: true, title: i18n.t('navigation.dashboard') }
    },
    {
        path: 'jobs',
        name: 'DriversJobsView',
        component: () => import('@/views/driver/jobs/DriversJobsView.vue'),
        meta: { requiresAuth: true, requiresDriver: true, title: i18n.t('navigation.jobs') }
    },
    {
        path: 'jobs/details/:id',
        name: 'DriversJobView',
        component: () => import('@/views/driver/jobs/DriversJobView.vue'),
        meta: { requiresAuth: true, requiresDriver: true, title: i18n.t('navigation.jobs') }
    },
    {
        path: 'inventory',
        name: 'DriversInventoryView',
        component: () =>
            import('@/views/driver/inventory/DriversInventoryView.vue'),
        meta: { requiresAuth: true, requiresDriver: true, title: i18n.t('navigation.inventory') }
    },
    {
        path: 'inventory/details/:id',
        name: 'DriversInventoryDetailView',
        component: () =>
            import('@/views/driver/inventory/DriversInventoryDetailView.vue'),
        meta: { requiresAuth: true, requiresDriver: true, title: i18n.t('navigation.inventory') }
    },
    {
        path: 'inventory/edit/:id',
        name: 'DriversInventoryEditView',
        component: () =>
            import('@/views/driver/inventory/DriversInventoryEditView.vue'),
        meta: { requiresAuth: true, requiresDriver: true, title: i18n.t('navigation.inventory') }
    },
    {
        path: 'expenses',
        name: 'DriversExpensesView',
        component: () =>
            import('@/views/driver/expenses/DriversExpensesView.vue'),
        meta: { requiresAuth: true, requiresDriver: true, title: i18n.t('navigation.expenses') }
    },
    {
        path: 'expenses/details/:id',
        name: 'DriversExpensesDetailView',
        component: () => import('@/views/expenses/ExpensesDetailView.vue'),
        meta: { requiresAuth: true, requiresDriver: true, title: i18n.t('navigation.expenses') }
    },
    {
        path: 'expenses/edit/:id',
        name: 'ExpensesEditView',
        component: () => import('@/views/expenses/ExpensesEditView.vue'),
        meta: { requiresAuth: true, requiresDriver: true, title: i18n.t('navigation.expenses') }
    },
    {
        path: 'operatives',
        name: 'DriversOperativesView',
        component: () =>
            import('@/views/driver/operatives/DriversOperativesView.vue'),
        meta: { requiresAuth: true, requiresDriver: true, title: i18n.t('navigation.operatives') }
    },
    {
        path: 'operatives/details/:id',
        name: 'DriversOperativesDetailsView',
        component: () =>
            import('@/views/driver/operatives/DriversOperativeView.vue'),
        meta: { requiresAuth: true, requiresDriver: true, title: i18n.t('navigation.operatives') }
    },
    {
        path: 'inventory/multiple',
        name: 'DriversMultipleInventoryView',
        component: () => import('@/views/shared/MultipleInventoryView.vue'),
        meta: { requiresAuth: true, requiresDriver: true, title: i18n.t('navigation.inventory') }
    },
    {
        path: 'free-forms/:tab?',
        name: 'DriversFreeFormsView',
        component: () => import('@/views/driver/DriversFreeFormsView.vue'),
        meta: { requiresAuth: true, requiresDriver: true, title: i18n.t('forms.free-form') }
    },
    {
        path: 'free-forms/:form_id/:form_submission_id?/edit',
        name: 'DriverFreeFormsFill',
        component: () => import('@/views/driver/DriverFreeFormsFill.vue'),
        meta: { requiresAuth: true, requiresDriver: true, title: i18n.t('forms.free-form') }
    },
    {
        path: 'free-forms/:form_id/:form_submission_id/details',
        name: 'DriverFreeFormsDetailsView',
        component: () =>
            import('@/views/driver/DriverFreeFormsDetailsView.vue'),
        meta: { requiresAuth: true, requiresDriver: true, title: i18n.t('forms.free-form') }
    },
    {
        path: 'resources',
        name: 'DriverResourcesView',
        component: () => import('@/views/driver/DriverResourcesView.vue'),
        meta: { requiresAuth: true, requiresDriver: true, title: i18n.t('navigation.resources') }
    },
    {
        path: 'settings',
        name: 'DriverSettings',
        component: () => import('@/views/team-lead/TeamLeadSettingsView.vue'),
        meta: { requiresAuth: true, requiresDriver: true, title: i18n.t('global.settings') }
    },
    {
        path: 'settings/edit/',
        name: 'DriverEditUserView',
        component: () => import('@/views/team-lead/EditUserView.vue'),
        meta: { requiresAuth: true, requiresDriver: true, title: i18n.t('global.settings') }
    },
    {
        path: '/notifications',
        name: 'DriverNotificationsView',
        component: () => import('@/views/shared/NotificationsView.vue'),
        meta: { requiresAuth: true, requiresDriver: true, title: i18n.t('dashboard.notifications') }
    }
]
