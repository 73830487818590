import { api } from './apiWrapper'

const baseURL = '/tenants'

const getTenant = async (id) => {
    const response = await api.get(`${baseURL}/${id}`)
    return response.data
}

const updateTenant = async (id, payload) => {
    return await api.put(`${baseURL}/${id}`, payload)
}

const downloadLogo = async (id) => {
    const url = `${baseURL}/${id}/download-logo`
    return await api.get(url)
}

const attachLogo = async (id, payload) => {
    const url = `${baseURL}/${id}/attach-logo`
    return await api.post(url, payload)
}

const getTenantBilling = async (id, params) => {
    const url = `${baseURL}/${id}/invoices`
    const response = await api.get(url, params)
    return response.data
}

const downloadInvoice = async (id, invoiceId) => {
    try {
        const url = `${baseURL}/${id}/invoices/${invoiceId}/download`
        const data = await api.get(url, null, { responseType: 'blob' })

        const fileURL = window.URL.createObjectURL(new Blob([data]))
        const fileLink = document.createElement('a')

        fileLink.href = fileURL
        fileLink.setAttribute('download', `${invoiceId}.pdf`)
        document.body.appendChild(fileLink)

        fileLink.click()

        if (!data) return []

        return data
    } catch (error) {
        console.error('Error in downloadInvoice:', error)
        throw error
    }
}

export default {
    getTenant,
    downloadLogo,
    updateTenant,
    attachLogo,
    getTenantBilling,
    downloadInvoice
}
