import { api } from './apiWrapper'

const baseURL = '/form-questions-answers'

const getAnswerById = async (id) => {
    return await api.get(`${baseURL}/${id}`)
}

const createAnswer = async (params) => {
    return await api.post(baseURL, params)
}

const updateMultipleAnswers = async (payload) => {
    return await api.post(`${baseURL}/update-multiple`, payload)
}

const deleteFormQuestion = async (answerId, mediaId) => {
    return await api.post(`${baseURL}/${answerId}/delete_media/${mediaId}`)
}

const getMedia = async (mediaId, mediaName = 'download') => {
    const url = `/download-media/${mediaId}`

    const data = await api.get(url, null, {
        responseType: 'blob'
    })

    const fileURL = window.URL.createObjectURL(new Blob([data]))
    const fileLink = document.createElement('a')

    fileLink.href = fileURL
    fileLink.setAttribute('download', mediaName)
    document.body.appendChild(fileLink)

    fileLink.click()

    if (!data) return []

    return data
}

export default {
    updateMultipleAnswers,
    getAnswerById,
    createAnswer,
    deleteFormQuestion,
    getMedia
}
