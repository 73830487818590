import { api } from './apiWrapper'
import db from '@/offline/indexedDBHelper'
import { fetchAndStoreData } from '.'

const baseURL = '/workschedules'

const getStatuses = async () => {
    const url = `${baseURL}/status-types`
    return await fetchAndStoreData(url, db.workschedule_status_types)
}

const addTeamLead = async (payload) => {
    const url = `${baseURL}/addteamlead`
    return await api.post(url, payload)
}

const addOperative = async (payload) => {
    const url = `${baseURL}/addoperative`
    return await api.post(url, payload)
}

const addPartimeOperative = async (payload) => {
    const url = `${baseURL}/add-part-time-operative`
    return await api.post(url, payload)
}

const removeOperative = async (payload) => {
    const url = `${baseURL}/removeoperative`
    return await api.post(url, payload)
}

const clone = async (payload) => {
    const url = `${baseURL}/clone`
    return await api.post(url, payload)
}

const sendSMS = async (payload) => {
    const url = `${baseURL}/send-notifications`
    return await api.post(url, payload)
}

const switchTeamLead = async (payload) => {
    const url = `${baseURL}/switch-team-lead`
    return await api.post(url, payload)
}

const updateRecord = async (id, payload) => {
    const url = `${baseURL}/${id}`
    return await api.patch(url, payload)
}

const getSignatures = async (id) => {
    return await api.get(`${baseURL}/${id}/signatures`)
}

const uploadSignature = async (id, payload) => {
    return await api.post(`${baseURL}/${id}/signature`, payload)
}

export default {
    getStatuses,
    addOperative,
    addTeamLead,
    clone,
    sendSMS,
    addPartimeOperative,
    removeOperative,
    switchTeamLead,
    updateRecord,
    getSignatures,
    uploadSignature
}
