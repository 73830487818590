import Vue from 'vue'
// import _ from 'lodash'
import API from '@/api'

export default {
    namespaced: true,
    state: {
        reports: {}
    },
    getters: {
        getReport: (state) => (report) => state.reports[report]
    },
    mutations: {
        SET_REPORT (state, { reportName, report }) {
            Vue.set(state.reports, reportName, report)
        }
    },
    actions: {
        getReports ({ commit }, { reportName, payload }) {
            return new Promise((resolve) => {
                try {
                    API.Reports[reportName](payload).then((report) => {
                        commit('SET_REPORT', { reportName, report })
                        resolve()
                    })
                } catch (e) {
                    console.error('Error in getReports:', e)
                }
            })
        }
    }
}
