import { api } from './apiWrapper'

const baseURL = '/ropehours'

const getAllRopeHours = async () => {
    return await api.get('/ropehours/1')
}

const getRopeHoursByWorkSchedule = async (workScheduleID) => {
    const url = `/workschedule?work_schedule_id=${workScheduleID}`
    return await api.get(url)
}

const updateRopeHours = async (ropeHourID, payload) => {
    const url = `${baseURL}/${ropeHourID}`
    return await api.patch(url, payload)
}

const uploadSignature = async (ropeHourID, image) => {
    const url = `${baseURL}/${ropeHourID}/signature`
    return await api.post(url, { image })
}

const getRopeHoursSignature = async (ropeHourID) => {
    const url = `${baseURL}/${ropeHourID}/download-signature`
    return await api.get(url, null, { responseType: 'arraybuffer' })
}

export default {
    getAllRopeHours,
    getRopeHoursByWorkSchedule,
    updateRopeHours,
    uploadSignature,
    getRopeHoursSignature
}
