
<BaseModal
    v-if="showConfirmation"
    @close="handleCancelMethod"
>
    <div class="base-confirmation">
        <div class="base-confirmation--title">
            {{ title }}
        </div>
        <div
            v-if="description"
            class="base-confirmation--description"
            v-html="description"
        />

        <div
            v-if="okMethod"
            class="flex w-full h-12"
            :class="{
                'mt-3': !description
            }"
        >
            <BaseButton
                class="w-full mr-2 bg-grey-600"
                @click="handleCancelMethod"
            >
                {{ $t('global.no-cancel') }}
            </BaseButton>

            <BaseButton
                :class="{
                    'bg-blue': type !== 'delete',
                    'bg-danger': type === 'delete'
                }"
                class="w-full text-white"
                :disabled="loading"
                :loading="loading"
                @click="handleOkMethod"
            >
                {{ okText }}
            </BaseButton>
        </div>

        <div
            v-else
            class="w-full h-12"
        >
            <BaseButton
                class="w-full h-full text-white bg-blue"
                @click="handleCancelMethod"
            >
                {{ $t('global.ok') }}
            </BaseButton>
        </div>
    </div>
</BaseModal>
