<template>
    <BaseModal
        v-if="showConfirmation"
        @close="handleCancelMethod"
    >
        <div class="base-confirmation">
            <div class="base-confirmation--title">
                {{ title }}
            </div>
            <div
                v-if="description"
                class="base-confirmation--description"
                v-html="description"
            />

            <div
                v-if="okMethod"
                class="flex w-full h-12"
                :class="{
                    'mt-3': !description
                }"
            >
                <BaseButton
                    class="w-full mr-2 bg-grey-600"
                    @click="handleCancelMethod"
                >
                    {{ $t('global.no-cancel') }}
                </BaseButton>

                <BaseButton
                    :class="{
                        'bg-blue': type !== 'delete',
                        'bg-danger': type === 'delete'
                    }"
                    class="w-full text-white"
                    :disabled="loading"
                    :loading="loading"
                    @click="handleOkMethod"
                >
                    {{ okText }}
                </BaseButton>
            </div>

            <div
                v-else
                class="w-full h-12"
            >
                <BaseButton
                    class="w-full h-full text-white bg-blue"
                    @click="handleCancelMethod"
                >
                    {{ $t('global.ok') }}
                </BaseButton>
            </div>
        </div>
    </BaseModal>
</template>

<script>
import EventBus from '../../utils/EventBus'

export default {
    name: 'BaseConfirmation',
    data () {
        return {
            showConfirmation: false,
            title: '',
            type: 'success',
            description: '',
            okText: '',
            okMethod: () => {},
            cancelMethod: () => {
                this.showConfirmation = false
            },
            loading: false
        }
    },
    mounted () {
        EventBus.$on('showConfirmation', (payload) => {
            this.handleShowConfirmation(payload)
        })
    },
    destroyed () {
        EventBus.$off('showConfirmation')
        this.description = ''
    },
    methods: {
        async handleOkMethod () {
            this.loading = true

            try {
                await this.okMethod()
                this.defaultClose()
            } catch (e) {
                this.$showAlert({
                    message: e,
                    type: 'error'
                })
            } finally {
                this.loading = false
            }
        },
        async handleCancelMethod () {
            if (this.cancelMethod) {
                await this.cancelMethod()
            }

            this.defaultClose()
        },
        defaultClose () {
            this.showConfirmation = false
            this.description = ''
        },
        handleShowConfirmation (payload) {
            this.showConfirmation = false

            if (!payload) return

            setTimeout(async () => {
                const {
                    title,
                    description,
                    type,
                    okMethod,
                    okText,
                    cancelMethod
                } = payload

                if (title) this.title = title
                if (description) this.description = description

                this.okText = okText || ''
                this.type = type || 'success'
                this.cancelMethod = cancelMethod || this.defaultClose
                this.okMethod = okMethod

                this.showConfirmation = true
            }, 200)
        }
    }
}
</script>

<style scoped lang="sass">

.base-confirmation
    height: 100%
    @apply text-center flex flex-col justify-between

    &--title
        @apply title-2 md:title-1 text-left

    &--description
        @apply py-8
</style>
