export default [
    {
        path: 'categories',
        name: 'CategoriesView',
        component: () => import('@/views/admin/categories/CategoriesView.vue'),
        meta: {
            title: 'My Abseil - Categories',
            requiresAuth: true,
            requiresAdmin: true
        }
    }
]
