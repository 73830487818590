export default [
    {
        path: 'jobs',
        name: 'JobsView',
        component: () => import('@/views/admin/jobs/JobsView.vue'),
        meta: {
            title: 'My Abseil - Jobs',
            requiresAuth: true,
            requiresAdmin: true
        }
    },
    {
        path: 'jobs/edit/:id',
        name: 'JobsEditView',
        component: () => import('@/views/admin/jobs/JobsEditView.vue'),
        meta: {
            title: 'My Abseil - Jobs',
            requiresAuth: true,
            requiresAdmin: true
        }
    },
    {
        path: 'jobs/details/:id',
        name: 'JobsDetailsView',
        component: () => import('@/views/admin/jobs/JobsDetailsView.vue'),
        meta: {
            title: 'My Abseil - Jobs',
            requiresAuth: true,
            requiresAdmin: true
        }
    }
]
