import moment from 'moment'
import store from '../store'

export default function (val) {
    if (!val) return ''

    return moment(val)
        .locale(store.getters['AuthModule/getUserLanguage'] ?? 'en')
        .format('H:mm A')
}
