// Imports
import AuthModule from '@/store/modules/AuthModule'
import ClientsModule from '@/store/modules/ClientsModule'
import InventoryModule from '@/store/modules/InventoryModule'
import JobsModule from '@/store/modules/JobsModule'
import InvoicesModule from '@/store/modules/InvoicesModule'
import NavigationModule from '@/store/modules/NavigationModule'
import OperativesModule from '@/store/modules/OperativesModule'
import UsersModule from '@/store/modules/UsersModule'
import WorkSchedulesModule from '@/store/modules/WorkSchedulesModule'
import InspectionsModule from '@/store/modules/InspectionsModule'
import ExpensesModule from '@/store/modules/ExpensesModule'
import FormsModule from '@/store/modules/FormsModule'
import TenantModule from '@/store/modules/TenantModule'
import ReportsModule from '@/store/modules/ReportsModule'
import DashboardModule from '@/store/modules/DashboardModule'
import ResourceModule from '@/store/modules/ResourceModule'

export default {
    TenantModule,
    AuthModule,
    ClientsModule,
    InventoryModule,
    JobsModule,
    InvoicesModule,
    NavigationModule,
    OperativesModule,
    UsersModule,
    WorkSchedulesModule,
    InspectionsModule,
    ExpensesModule,
    FormsModule,
    ReportsModule,
    DashboardModule,
    ResourceModule
}
