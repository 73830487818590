// Imports
export default {
    BaseButton: () => import('@/components/globals/BaseButton.vue'),
    BaseIcon: () => import('@/components/globals/BaseIcon.vue'),
    BaseCard: () => import('@/components/globals/BaseCard.vue'),
    BaseDateInput: () => import('@/components/globals/BaseDateInput.vue'),
    BaseDatePicker: () => import('@/components/globals/BaseDatePicker.vue'),
    BaseDivider: () => import('@/components/globals/BaseDivider.vue'),
    BaseDropdown: () => import('@/components/globals/BaseDropdown.vue'),
    BaseDropdownItem: () => import('@/components/globals/BaseDropdownItem.vue'),
    BaseExpandable: () => import('@/components/globals/BaseExpandable.vue'),
    BaseInput: () => import('@/components/globals/BaseInput.vue'),
    BaseLoadingSpinner: () => import('@/components/globals/BaseLoadingSpinner.vue'),
    BaseModal: () => import('@/components/globals/BaseModal.vue'),
    BaseModelTable: () => import('@/components/globals/BaseModelTable.vue'),
    BaseNotificationPeriod: () =>
        import('@/components/globals/BaseNotificationPeriod.vue'),
    BasePagination: () => import('@/components/globals/BasePagination.vue'),
    BaseRadioButton: () => import('@/components/globals/BaseRadioButton.vue'),
    BaseSelect: () => import('@/components/globals/BaseSelect.vue'),
    BaseSingleSelect: () => import('@/components/globals/BaseSingleSelect.vue'),
    BaseTabs: () => import('@/components/globals/BaseTabs.vue'),
    BaseTable: () => import('@/components/globals/BaseTable.vue'),
    BaseTag: () => import('@/components/globals/BaseTag.vue'),
    BaseTextarea: () => import('@/components/globals/BaseTextarea.vue'),
    BaseTimeline: () => import('@/components/globals/BaseTimeline.vue'),
    BaseUpload: () => import('@/components/globals/BaseUpload.vue'),
    BaseYesNoCheckbox: () => import('@/components/globals/BaseYesNoCheckbox.vue'),
    BasePdfConverter: () => import('@/components/globals/BasePdfConverter.vue'),
    TableSorting: () => import('@/components/globals/TableSorting.vue'),
    HeaderSortable: () => import('@/components/globals/HeaderSortable.vue'),
    ArrowUp: () => import('@/components/globals/icons/ArrowUp.vue'),
    ArrowDown: () => import('@/components/globals/icons/ArrowDown.vue'),
    ActiveFilters: () => import('@/components/globals/ActiveFilters.vue'),
    BaseBackToTop: () => import('@/components/globals/BaseBackToTop.vue'),
    BaseBarcodeScanner: () => import('@/components/globals/BaseBarcodeScanner.vue'),
    BaseExportLoading: () => import('@/components/globals/BaseExportLoading.vue'),
    BaseRichEditor: () => import('@/components/globals/BaseRichEditor.vue'),
    BaseDynamicChecklist: () => import('@/components/globals/BaseDynamicChecklist.vue'),
    BaseMediaUpload: () => import('@/components/globals/BaseMediaUpload.vue'),
    InfiniteScrollTrigger: () => import('@/components/globals/InfiniteScrollTrigger.vue'),
    BaseOverlay: () => import('@/components/globals/BaseOverlay.vue'),
    BasePanelActions: () => import('@/components/globals/BasePanelActions.vue'),
    BaseCategoryList: () => import('@/components/globals/BaseCategoryList.vue')
}
