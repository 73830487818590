import _ from 'lodash'
import API from '@/api'

export default {
    namespaced: true,
    state: {
        jobs: [],
        jobsStatuses: [],
        jobTypes: []
    },
    getters: {
        getJobs: (state) => state.jobs,
        getJobsStatuses: (state) => state.jobsStatuses,
        getJobTypes: (state) => state.jobTypes
    },
    mutations: {
        SET_JOBS (state, jobs) {
            state.jobs = jobs
        },
        SET_JOBS_STATUSES (state, jobsStatuses) {
            state.jobsStatuses = jobsStatuses
        },
        SET_JOB_TYPES (state, jobTypes) {
            state.jobTypes = jobTypes
        }
    },
    actions: {
        async setJobs ({ commit }, params = {}) {
            try {
                if (Object.prototype.hasOwnProperty.call(params, 'autocomplete')) {
                    if (params.autocomplete.length < 2) {
                        commit('SET_JOBS', [])
                        return
                    }
                }
                const jobs = await API.Models.getAll('jobs', { ...params, sort: 'name' })
                commit('SET_JOBS', jobs)
            } catch (e) {
                console.error('Error in setJobs:', e)
            }
        },
        async setJobStatuses ({ commit, state }) {
            try {
                if (!_.isEmpty(state.jobsStatuses)) return
                const jobsStatuses = await API.Jobs.getStatuses()
                commit('SET_JOBS_STATUSES', jobsStatuses)
            } catch (e) {
                console.error('Error in setJobStatuses:', e)
            }
        },
        async setJobTypes ({ commit }) {
            try {
                const categories = await API.Tags.getTags({
                    type: 'Job'
                })
                commit('SET_JOB_TYPES', categories?.data || [])
            } catch (e) {
                console.error('Error in setJobTypes:', e)
            }
        }
    }
}
