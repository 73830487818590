import db from '@/offline/indexedDBHelper'
import { fetchAndStoreData } from '.'
import { api } from './apiWrapper'

const baseURL = '/expenses'

const getCurrencyTypes = async () => {
    return await fetchAndStoreData(`${baseURL}/currencies-types`, db.currencies)
}

const getAllExpenses = async (params) => {
    return await api.get(`${baseURL}`, params)
}

const getExpenseByID = async (expenseID) => {
    const response = await api.get(`${baseURL}/${expenseID}`)
    return response.data
}

const createExpense = async (payload) => {
    const response = await api.post(baseURL, payload)
    return response.data
}

const updateExpense = async (expenseID, payload) => {
    const response = await api.path(`${baseURL}/${expenseID}`, payload)
    return response.data
}

const getExpenseAttachments = async (expenseID) => {
    return await api.get(`${baseURL}/${expenseID}/attachments`)
}

const uploadExpenseAttachment = async (expenseID, payload) => {
    return await api.post(`${baseURL}/${expenseID}/attachfile`, payload)
}

export default {
    getAllExpenses,
    getExpenseByID,
    createExpense,
    updateExpense,
    getExpenseAttachments,
    uploadExpenseAttachment,
    getCurrencyTypes
}
