
<MainLayout
    class="bg-white"
    padding="10px"
>
    <div v-if="!loading">
        <div class="flex items-center justify-between flex-wrap">
            <div />

            <div class="flex xl:contents">
                <div class="heading-1 text-grey-800 flex">
                    <p class="my-auto mr-3">
                        {{ getDate | DateFilter }}
                    </p>
                </div>

                <div class="flex justify-end w-1/4">
                    <div class="mx-3 my-auto job-allocation-view--filter">
                        <BaseDatePicker
                            v-model="getDate"
                            date-format="ddd, DD-MMM-YY"
                        />
                    </div>
                </div>
            </div>
        </div>
        <span
            class="w-full flex justify-center pr-0 xl:pr-[25%]"
            :class="{
                'text-xl font-bold': getJobName.length > 25,
                'title-1': getJobName.length > 15 && getJobName.length <= 25,
                title: getJobName.length <= 15
            }"
        >
            {{ getJobName }}
        </span>

        <div v-if="workSchedule.id">
            <BaseTabs
                v-model="currentTab"
                :tabs="jobsAllocationTabs"
                class="my-8 border-b border-grey-100"
                @input="setTab"
            />

            <transition
                name="fade"
                mode="out-in"
            >
                <component
                    :is="currentTab.component"
                    v-if="showComponent"
                    :work-schedule="workSchedule"
                    :model="{id: workSchedule.job.id, path: 'jobs'}"
                    @add-operative="loadWorkSchedule"
                    @reload="loadWorkSchedule(false)"
                />
                <div
                    v-else
                    class="my-3"
                >
                    <QuestionList
                        read-only
                        :questions="questions"
                        @answer="handleAnswer"
                    />
                </div>
            </transition>
        </div>
        <BaseCard
            v-else
            class="w-1/2 px-10 py-4 mx-auto mt-32 text-center bg-white title-2"
        >
            {{ $t('job-allocation.no-work-schedule') }}
            <br>
            {{ $t('job-allocation.select-new-date') }}
        </BaseCard>
    </div>
</MainLayout>
