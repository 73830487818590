import db from '@/offline/indexedDBHelper'
import { fetchAndStoreData } from '.'
import { api } from './apiWrapper'

const baseURL = '/form-questions'

const getAllQuestions = async (params) => {
    return await api.get(baseURL, params)
}

const updateMultipleQuestions = async (payload) => {
    return await api.post(`${baseURL}/update-multiple`, payload)
}

const createQuestion = async (payload) => {
    return await api.post(baseURL, payload)
}

const updateQuestion = async (formID, payload) => {
    return await api.patch(`${baseURL}/${formID}`, payload)
}

const deleteQuestion = async (formID) => {
    return await api.delete(`${baseURL}/${formID}`)
}

const getInputTypes = async () => {
    const url = `${baseURL}/input-types`
    return await fetchAndStoreData(url, db.form_questions_input_types)
}

export default {
    getAllQuestions,
    createQuestion,
    updateQuestion,
    deleteQuestion,
    getInputTypes,
    updateMultipleQuestions
}
