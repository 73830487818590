
<div
    class="base-notify-alert"
    :style="getAlertStyle"
    :color="`${alert.type}`"
>
    <i
        :color="getIconClass"
        class="mx-3 text-xl"
        :class="getIcon"
    />

    <span class="px-5">{{ alert.message }}</span>

    <BaseButton
        icon
        :color="getIconClass"
        @click="removeAlert(alert)"
    >
        <i class="fas fa-times" />
    </BaseButton>
</div>
