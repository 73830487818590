import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist'
import Cookies from 'js-cookie'

import Modules from './modules'

Vue.use(Vuex)

const vuexCookie = new VuexPersist({
    restoreState: () => Cookies.getJSON('my-abseil'),
    saveState: (key, state) =>
        Cookies.set('my-abseil', state, {
            expires: 3
        }),
    modules: ['AuthModule']
})

export default new Vuex.Store({
    plugins: [vuexCookie.plugin],
    state: {
        globalDate: new Date(),
        loader: false,
        langs: [
            { text: 'English', value: 'en' },
            { text: 'French', value: 'fr' }
        ],
        disableLoader: false
    },
    getters: {
        getGlobalDate: (state) => state.globalDate,
        getLoaderState: (state) => state.loader,
        getLangs: (state) => state.langs,
        getDisableLoaderState: (state) => state.disableLoader
    },
    mutations: {
        SET_GLOBAL_DATE (state, date) {
            state.globalDate = date
        },
        SET_LOADER_TO_STATE (state, condition) {
            state.loader = condition
        },
        SET_DISABLE_LOADER_TO_STATE (state, condition) {
            state.disableLoader = condition
        }
    },
    actions: {},
    modules: {
        ...Modules
    }
})
